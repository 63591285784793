<template>
	<div>
		<div class="queryBox">
			
			<div class="customBanner flexX center mb10">
				<div class="flexX flexcenter" style="width: 150px;">
					<span class="mr20">自动刷新</span>
					<el-switch
					  :width="switchWitdh"
					  v-model="refresh"
					  active-color="#13ce66"
					  inactive-color="#ff4949"
					  size="mini" 
					  @change="setRefresh">
					</el-switch>
				</div>
				<div class="flexX flexcenter" style="width: 300px;">
					<span class="mr20">刷新频率</span>
					<el-input size="mini" class="mr20" type="number" v-model="frequency" maxlength="5" style="width: 70px;"></el-input>
					<el-button size="mini" type="primary" @click="setFrequency">保存设置</el-button>
				</div>
				
			</div>
			<div class="mb20">
				<el-button size="mini" plain class="mr20" @click="setDate(-1)" :disabled="disLoading">前一天</el-button>
				<el-date-picker size="mini" v-model="date" type="daterange" range-separator="至" start-placeholder="开始日期"
					end-placeholder="结束日期" class="mr20"></el-date-picker>
				<el-button size="mini" plain class="mr20" @click="setDate(1)" :disabled="disLoading">后一天</el-button>
				
				<el-select v-model="pay_type" placeholder="请选择通道" size="mini" class="mr20" clearable>
					<el-option v-for="(item,index) in payTypeArr" :key="index" :label="item" :value="index"></el-option>
				</el-select>
					<span class="ml20">状态：</span>
					<el-select size="mini" class="mr20" v-model="state" clearable placeholder="请选择状态">
						<el-option v-for="(item, index) in stateArrayAli" :key="item.key" :label="item.val" :value="item.key">
						</el-option>
					</el-select>
					
					<el-input size="mini" placeholder="请输入订单号" v-model="order_id" clearable class="mr20" style="width: 156px"></el-input>
					<el-input size="mini" placeholder="请输入交易单号" v-model="water_no" clearable class="mr20" style="width: 156px"></el-input>
				
				<el-button size="mini" type="primary" :icon="disLoading ? 'el-icon-loading' : 'el-icon-search'" @click="getData" :disabled="disLoading">查询</el-button>
			</div>
		</div>
		<template>
			<el-table  fit size="mini" :max-height="height" :data="tableData"  stripe v-loading="loading" :show-overflow-tooltip="true" v-if="$payType == 1">
				<el-table-column type="index" label="序号" ></el-table-column>
				<el-table-column  prop="trader_order_id" label="订单号">
					<template slot-scope="scope">						
						<div class="flexY flexStart">
							<div class="flexX flexJustifyStart">
								<div class="whiteEllipsisHover" >商户:{{scope.row.trader_order_id}}</div>
								<span> <i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(scope.row.trader_order_id)"></i></span>
							</div>
							<div class="flexX flexJustifyStart">
								<div class="whiteEllipsisHover" >系统:{{scope.row.water_no}}</div>
								<span> <i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(scope.row.water_no)"></i></span>
							</div>
						</div>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="water_no" label="交易号"></el-table-column> -->
				<!-- <el-table-column prop="true_name" label="姓名"></el-table-column> -->
				<el-table-column prop="mobile_name" label="机位"></el-table-column>
				<el-table-column prop="card" label="收款信息" width="200px">
					<template slot-scope="scope">						
						<div class="flexY flexStart">
							<div class="flexX flexJustifyStart">
								<div class="" >账号:{{scope.row.card}}</div>
								<!-- <span> <i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(scope.row.card)"></i></span> -->
							</div>
							<div class="flexX flexJustifyStart">
								<div class="whiteEllipsisHover" >类型:{{scope.row.bank}}</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="bank" label="类型" ></el-table-column> -->
				<el-table-column prop="pay_type_name" label="通道" ></el-table-column>
				<!-- <el-table-column label="口令" align="center" width="100px">
					<template slot-scope="scope">
						<div v-if="scope.row.redKey" class="fs12">
							<el-tag type="success" effect="plain">{{scope.row.redKey}}</el-tag>
						</div>
					</template>
				</el-table-column> -->
				<!-- <el-table-column label="收款二维码" align="center">
					<template slot-scope="scope" v-if="scope.row.alipay_qr_url">
						<el-image
							style="width: 30px; height: 50px"
							v-if="scope.row.alipay_qr_url != ''"
							:src="scope.row.alipay_qr_url"
							fit="fill"
							:preview-src-list="[scope.row.alipay_qr_url]">
						</el-image>
						
					</template>
				</el-table-column> -->
				<el-table-column prop="" label="金额" >
					<template slot-scope="scope">
						{{ scope.row.gold | tofixed }}
					</template>
				</el-table-column>
				<el-table-column prop="pay_name" label="付款人姓名" ></el-table-column>
				
				<el-table-column label="订单状态" >
					<template slot-scope="scope">
						<div v-if="scope.row.id" :class="scope.row.state==1?'csuccess':scope.row.state==7?'cdanger':''">{{scope.row.state_name}}</div>
					</template>
				</el-table-column>
				<el-table-column class="cdanger" prop="close_reason" label="驳回原因" >
					<template slot-scope="scope">
					  <el-select v-if="scope.row.state==7" v-model="scope.row.close_reason" placeholder="请选择" @change="reasonChange($event, scope.row)">
					    <el-option
					      v-for="(item, index) in closeStateObj"
					      :key="index"
					      :label="item"
					      :value="item"
						  >
					    </el-option>
					  </el-select>
					</template>
					
				</el-table-column>
				<el-table-column prop="add_time" label="时间"width="160px">
					<template slot-scope="scope">						
						<div class="flexY flexJustifyStart">
							提交:{{scope.row.add_time}}<br>
							完成:{{scope.row.action_time}}<br>
							<span class="cdanger">耗时:{{(scope.row.action_time && scope.row.add_time)?getTimeDifference(scope.row.add_time,scope.row.action_time):''}}</span>
						</div>
					</template>
					<!-- <template slot-scope="scope">
						<div v-if="scope.row.add_time">
							{{ scope.row.add_time.split(" ")[0] }}
							<br>
							{{ scope.row.add_time.split(" ")[1] }}
						</div>

					</template> -->
				</el-table-column>

				<el-table-column label="操作" >
					<template slot-scope="scope">
						<div v-if="scope.row.state == 2">
							<!-- <el-link type="primary">确认收款</el-link> -->
							<el-link  v-if="scope.row.state == 2"  size="mini" type="primary" @click="ensure(scope.row)" :disabled="disLoading">确认收款</el-link >
						</div>
						<div v-if="scope.row.state == 7">
							<el-link  v-if="scope.row.state == 7" type="primary" @click="showDia(scope.row,scope.$index)">补单</el-link>
						</div>
						<div  v-if="scope.row.state == 2" class="mt10">
							<el-link v-if="scope.row.state == 2" size="mini" type="danger" @click="showClose(scope.row)" :disabled="disLoading">驳回订单</el-link >
						</div>
					</template>
				</el-table-column>
			</el-table>

			<el-table size="mini" :max-height="height" :data="tableData" stripe v-loading="loading"
				:show-overflow-tooltip="true" v-if="$payType == 2">
				<el-table-column type="index" label="序号" width="80px" ></el-table-column>
				<el-table-column prop="water_no" label="订单号" >
					<template slot-scope="scope">						
						<div class="flexY flexStart">
							<div class="flexX flexJustifyStart">
								<div class="whiteEllipsisHover" >商户:{{scope.row.trader_order_id}}</div>
								<span> <i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(scope.row.trader_order_id)"></i></span>
							</div>
							<div class="flexX flexJustifyStart">
								<div class="whiteEllipsisHover" >系统:{{scope.row.water_no}}</div>
								<span> <i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(scope.row.water_no)"></i></span>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="store_name" label="店铺名称"></el-table-column>
				<!-- <el-table-column prop="card" label="卡号" ></el-table-column>
				<el-table-column prop="bank" label="银行" ></el-table-column> -->
				<el-table-column prop="" label="金额" >
					<template slot-scope="scope">
						{{ scope.row.gold | tofixed }}
					</template>
				</el-table-column>
				<el-table-column prop="state_name" label="订单状态"></el-table-column>
				<el-table-column prop="action_time" label="提交时间">
					<template slot-scope="scope">
						<div v-if="scope.row.action_time">
							{{ scope.row.action_time.split(" ")[0] }}
							<br>
							{{ scope.row.action_time.split(" ")[1] }}
						</div>

					</template>
				</el-table-column>
			</el-table>
		</template>
		<div class="flexX  mt20 pageBox">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page" :page-sizes="pageSizesArr" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
		<el-dialog title="确认订单信息" :visible.sync="dialogVisible" center width="30%" @close="dialogClose" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
			<div class="sure-order-comfir" v-if="item.pay_type != 1111">
				<div class=" order-info-item"><span>订单号：</span><span>{{ item.trader_order_id }}</span></div>
				<div class="mt20 order-info-item"><span>交易号：</span><span>{{ item.water_no }}</span></div>
				<div class="mt20 order-info-item"><span>订单时间：</span><span>{{ item.add_time}}</span></div>
				<div class="mt20 order-info-item"><span>金额：</span><span>{{ item.true_gold }}</span></div>
				<div class="mt20 order-info-item"><span>账号：</span><span>{{ item.alipay_account }}</span></div>
				<div class="mt20 order-info-item"><span>真实姓名：</span><span>{{ item.alipay_true_name }}</span></div>
				<div class="mt20 order-info-item"><span>付款人:</span><span>{{ item.pay_name }}</span></div>
			</div>
			<div class="sure-order-comfir" v-if="item.pay_type == 1111">
				<div class=" order-info-item"><span>商城订单号：</span><span>{{ item.shop_order }}</span></div>
				<div class="mt20 order-info-item"><span>金额：</span><span>{{ item.true_gold }}</span></div>
				<div class="mt20 order-info-item"><span>商城：</span><span>{{ item.store_name }}</span></div>
			</div>
			<div class="mt20 order-info-item">
				<el-form label-position="left" label-width="100px">
					<el-form-item label="确认密码">
						<el-input v-model="opcode" placeholder="确认密码"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogClose">取 消</el-button>
				<el-button type="primary" @click="ensureOrder">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog width="600px" center top="60px" title="补单" :visible.sync="bddialogVisible" @close="bdclose" :close-on-click-modal="false">
			<div class="formBoxbd" v-loading="bddialogloading">
				<div>
					<el-form label-position="right" label-width="100px">
						<el-form-item label="订单编号">
							{{bditem.order_id}}
						</el-form-item>
						<el-form-item label="交易号">
							{{bditem.water_no}}
						</el-form-item>
						<!-- <el-form-item label="商家名称">
							{{bditem.business_name}}
						</el-form-item> -->
						<el-form-item label="订单金额">
							{{bditem.add_gold | tofixed}}元
						</el-form-item>
						<el-form-item label="付款人">
							{{bditem.pay_name}}
						</el-form-item>
						<el-form-item label="补单类型">
							<el-select v-model="replenState" placeholder="请选择补单类型" class="mr20" clearable>
								<el-option v-for="(item,index) in replenishmentStateObj" :key="index" :label="item" :value="index"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="补单金额">
							<el-input v-model="amount" placeholder="请输入补单金额"></el-input>
						</el-form-item>
						<el-form-item label="补单密码">
							<el-input v-model="opcode" placeholder="补单密码"></el-input>
						</el-form-item>
					</el-form>
				</div>
				<div class="textCenter">
					<el-button type="primary" @click="onSubmit">确定</el-button>
				</div>
			</div>
		</el-dialog>
		
		
		<el-dialog width="800px" center top="100px" title="驳回" :visible.sync="closedialogVisible" @close="closeclose" :close-on-click-modal="false">
			<div class="formBoxbd" v-loading="closedialogloading">
				<div>
					<el-form label-position="right" label-width="100px">
						<el-form-item label="订单编号">
							{{closeItem.order_id}}
						</el-form-item>
						<el-form-item label="交易号">
							{{closeItem.water_no}}
						</el-form-item>
						<!-- <el-form-item label="商家名称">
							{{closeItem.business_name}}
						</el-form-item> -->
						<el-form-item label="订单金额">
							{{closeItem.add_gold | tofixed}}元
						</el-form-item>
						<el-form-item label="付款人">
							{{closeItem.pay_name}}
						</el-form-item>
						<el-form-item label="驳回备注">
							<el-select v-model="closeState" placeholder="请选择补单类型" size="mini" class="mr20" clearable>
								<el-option v-for="(item,index) in closeStateObj" :key="index" :label="item" :value="index"></el-option>
							</el-select>
						</el-form-item>
					</el-form>
				</div>
				<div class="textCenter">
					<el-button type="primary" @click="closeOrder(closeItem)">驳回</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import local from '@/util/local'
import {
	isEmpty,
	isPositiveNumber,
	isMoney,
	isMobile,
	isIncludeHanzi
} from '@/util/validate'
import {
	Message
} from 'element-ui'
export default {
	data() {
		return {
			loading: false,
			disLoading: false,
			height: 0,
			tableData: [],
			pageSizesArr: [20, 50, 100, 200],
			pageSize: 20,
			total: 0,
			page: 1,
			date: null,
			state:3,
			stateArrayAli: [{
				key: 1,
				val: "已完成"
			},
			{
				key: 2,
				val: "等待付款"
			},
			{
				key: 3,
				val: "全部"
			},
			],
			dialogVisible: false,
			item: {},
			order_id: '',
			water_no: '',
			opcode: '',
			//补单
			replenState: '',
			replenishmentStateObj: {}, //补单类型
			bddialogVisible: false, //补单弹框
			amount: '', //补单金额
			bditem: {}, //当前补单的数据
			index: {}, //当前item 索引
			// orderType:"",//订单操作类型  cancle 撤单
			bddialogloading: false,
			trader_order_id: '',
			payTypeArr:[],
			pay_type: '',
			// 自动刷新
			switchWitdh: 35,
			refresh: true,
			frequency: 20,
			// 驳回
			closeState: '',
			closeStateObj: {}, //补单类型
			closedialogVisible: false, //补单弹框
			closedialogloading: false,
			closeItem: {}
		};
	},
	created() {
		this.date = [this.$util.timestampToTime(new Date().getTime(), true), this.$util.timestampToTime(new Date().getTime(),true)];
		// 自动刷新
		this.refresh = local.get('refresh')
		this.frequency = local.get('frequency')
		if(this.refresh == null || this.refresh == false) this.refresh = true
		if(this.frequency == null || this.frequency == false) this.frequency = 60
		
		document.addEventListener('visibilitychange', this.handleVis1('create'))
		if(this.refresh == true){
			this.addTimer()
		}
			
		this.orderInList()
		this.payTypeAll()
		this.closeStateFun()
		this.$nextTick(() => {
			let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
			let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
			let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
			let documentHeight = document.documentElement.clientHeight
			this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
		})
	},
	beforeDestroy(){
		document.removeEventListener('visibilitychange', this.handleVis1('destroy'))
		clearInterval(this.timer);
	},
	filters: {
		tofixed: function (value) {
			if (value || value == 0) {
				let val = Math.floor(((value * 1) * 100).toPrecision(12))
				return ((val / 100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
			}
		}
	},
	methods: {
		reasonChange(val, item){
			let data = {
				id: item.id,
				closeReason: val
			}
			this.$api.setReason(data).then(res => {
				if (res.status == 1) {
					this.orderInList()
					this.$message({
						type: 'success',
						message: res.msg
					})
				} else {
					this.$message({
						type: 'error',
						message: res.msg
					})
				}
			})
		},
		// 驳回
		closeclose() {
			this.closeItem = {}
			this.closedialogloading = false
			this.closedialogVisible = false
			// this.closeStateObj = {}
			this.closeState = ''
		},
		//驳回
		closeStateFun() {
			this.$api.closeReason({}).then(res => {
				if (res.status == 1) {
					this.closeStateObj = res.data
				} else {
					this.$message({
						type: 'error',
						message: res.msg
					})
				}
			})
		},
		// 驳回
		showClose(item, index) {
			this.closeItem = item
			this.closedialogVisible = true
			
			if (JSON.stringify(this.closeStateObj) == '{}') {
				this.closeStateFun()
			}

		},
			//驳回订单
			closeOrder(item) {
				let data = {
					id: item.id,
					closeReason: this.closeState
				}
				this.closedialogloading = true
				this.$api.closeOrder(data).then(res => {
					this.closedialogloading = false
					if (res.status == 1) {
						this.orderInList()
						this.closeclose()
						this.$message({
							type: 'success',
							message: '驳回成功'
						});
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						});
					}
				}).catch(error => {
					this.closedialogloading = false
				})
				
			},
			handleVis1(val){
				
				if(document.visibilityState === 'hidden' || val == 'destroy'){
					clearInterval(this.timer);
				}else{
					this.addTimer()
				}
			},
			addTimer(){
				clearInterval(this.timer);
				if(this.refresh == true){
					this.timer = setInterval(()=>{
							this.orderInList()
					},this.frequency*1000)
				}
			},
			setRefresh(val) {
				local.set('refresh', val)
				this.addTimer()
			},
			setFrequency() {
				local.set('frequency', this.frequency)
				this.addTimer()
			},
			payTypeAll() {
				this.$api.enableList({
					hasRepay: 2
				}).then(res => {
					if (res.status === 1) {
						this.payTypeArr = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},

		//补单按钮
		replenishmentState() {
			this.$api.replenishmentState({}).then(res => {
				if (res.status == 1) {
					this.replenishmentStateObj = res.data
				} else {
					this.$message({
						type: 'error',
						message: res.msg
					})
				}
			})
		},
		showDia(item, index) {
			this.bditem = item
			this.index = index
			// this.orderType=type
			this.bddialogVisible = true
			if (JSON.stringify(this.replenishmentStateObj) == '{}') {
				this.replenishmentState()
			}

		},
		//确认补单
		onSubmit() {
			if (isEmpty(this.replenState, '请选择补单类型')) {
				return
			}
			if (isEmpty(this.amount, '请选输入补单金额')) {
				return
			}
			if (!isMoney(this.amount, '补单金额只能是数字，且最多保留两位小数')) {
				return
			}
			let data = {
				id: this.bditem.id,
				state: this.replenState,
				amount: this.amount,
				opcode: this.$md5(this.opcode)
			}
			this.bddialogloading = true;
			this.$api.replenishment(data).then(res => {
				this.bddialogloading = false;
				if (res.status == 1) {
					this.orderInList()
					this.bdclose();
				} else {
					this.$message({
						type: 'error',
						message: res.msg
					})
				}
			}).catch(() => {
				this.bddialogloading = false;
			})
		},
		bdclose() {
			this.bditem = {}
			this.bddialogVisible = false
			this.replenState = ''
			this.amount = ''
			this.opcode = ''
		},
		setDate(day) {
			let data = this.date ? this.date[0] : this.$util.timestampToTime(new Date().getTime(), true)
			let newDate = this.$util.getLastDate(data, day);
			this.date = [newDate, newDate]
			this.getData()
		},
		getTimeDifference(startTime, endTime) {
			return this.$util.intervalTime(startTime, endTime)
		},
		//搜索按钮
		getData() {
			this.page = 1;
			this.orderInList();
		},

		handleSizeChange(val) {
			// console.log(`每页 ${val} 条`);
			this.pageSize = val
			this.page = 1
			this.orderInList()
		},
		handleCurrentChange(val) {
			// console.log(`当前页: ${val}`);
			this.page = val
			this.orderInList()
		},
		orderInList() {
			let data = {
				// date_time: this.showDate ? this.date : '',
				start_time: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[0])), true) : '',
				end_time: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[1])), true) : '',
				app_type: this.$payType,
				page: this.page,
				size: this.pageSize,
				state: this.state == 3 ? '' : this.state,
				trader_order_id: this.order_id,
				water_no: this.water_no,
				pay_type: this.pay_type
			}
			this.disLoading = true
			this.$api.orderInList(data).then(res => {
				this.loading = false;
				this.disLoading = false;
				if (res.status === 1) {
					this.tableData = res.data.data;
					this.total = res.data.total
				} else {
					this.$message({
						type: 'error',
						message: res.msg
					})
				}
			}).catch(error => {
				this.loading = false
				this.disLoading = false
			})
		},
		ensure(item) {
			if (this.loading) {
				return
			}
			this.item = item
			this.dialogVisible = true
		},
		ensureOrder() {
			if (this.loading) {
				return
			}
			this.loading = true
			let data = {
				id: this.item.id,
				opcode: this.$md5(this.opcode)
			}
			this.$api.ensureOrder(data).then(res => {
				this.loading = false
				if (res.status == 1) {
					this.$message({
						type: 'success',
						message: res.msg
					})
					this.orderInList()
					this.dialogClose()
				} else {
					this.$message({
						type: 'error',
						message: res.msg
					})
				}

			}).catch(e => {
				this.loading = false
			})
		},
		dialogClose() {
			if (this.loading) {
				return
			}
			this.item = {}
			this.dialogVisible = false
		},
		handleCopy(textCopy){
				
				const contentToCopy = textCopy
				// 创建一个临时的 textAREA 元素
				const textarea = document.createElement('textarea')
				textarea.value = contentToCopy
				document.body.appendChild(textarea)
				textarea.select()
				document.execCommand('copy')
				document.body.removeChild(textarea)
				this.$message({
					type: 'success',
					message: '已复制到剪切板'
				})
			}
	},
	watch: {
		total(val, old) {
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
			})
		}
	},
};
</script>

<style scoped="scoped" lang="scss">
.formBoxbd {
	width: 80%;
	margin: 0 auto;
}
</style>
<style type="text/css">
.avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}

.avatar-uploader .el-upload:hover {
	border-color: #409EFF;
}

.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 178px;
	height: 178px;
	line-height: 178px;
	text-align: center;
}

.avatar {
	width: 178px;
	height: 178px;
	display: block;
}

.sure-order-comfir .order-info-item span:nth-child(1) {
	display: inline-block;
	width: 100px;

}

</style>
