<template>
	<div id="app">
		<div class="topNav" :class="setBackgroundColor($PayNameImg)" v-if="!this.$route.meta.noNav">
			<div class="navbar flexX flexcenter">
				<!-- <div class="logo" @click="showDataAll">首页</div> -->
				<div class="logo" @click="showDataAll">
					<span v-if="$PayNameImg == 'mark'"><i class="el-icon-coin cdanger"></i> MarkPay</span>
					<span v-else-if="$PayNameImg == 'rb'"><i class="el-icon-coin cdanger"></i> RbPay</span>
					<span v-else-if="$PayNameImg == 'bitong'"><i class="el-icon-coin cdanger"></i> 币通</span>
					<span v-else-if="$PayNameImg == 'qixing'"><i class="el-icon-coin cdanger"></i> 柒星</span>
					<span v-else-if="$PayNameImg == 'aipay'"><i class="el-icon-coin cdanger"></i> AiPay</span>
					<span v-else><i class="el-icon-coin cdanger"></i> Ttpay</span>
					
				</div>
				<!-- <div style="padding: 0 15px;" @click="toggleClick">
					<div class="hamburger" :class="{'is-active':showSide==='hide'}">
						<div class="icon"></div>
					</div>
				</div> -->
			</div>
			<div class="systemCount " v-if="false">
				<span class="mr20 pointer" @click="gotopageOrderPay()" v-if="$payType!=2"><span class="cnavTop">未处理代付订单：</span><span
						class="cwarning fs20">{{systemcount.manual_order_count}}</span></span>
				<span class="mr20 pointer" @click="gotopageSetTrueQuota()" v-if="$payType!=2"><span class="cnavTop">卡余额待修正：</span><span
						class="cwarning fs20">{{systemcount.card_count}}</span></span>
				<span class="mr20 pointer" @click="gotopagePassage()" v-if="$payType!=2"><span class="cnavTop">掉线卡待处理：</span><span
						class="cwarning fs20">{{systemcount.card_offline_count}}</span></span>

			</div>
			<div class="flexX flexcenter">	
				<div class="mr40 flexX flexcenter">	
					<span class="mr10 username flexX flexcenter"><el-avatar size="small" style="background-color: #5bc0de; "> {{username}} </el-avatar> {{username}}</span>
					<!-- <span>{{this.InfoData.state== 0? '启用':'停用'}}</span> -->
					<el-tag :class="this.InfoData.state== 0? 'el-icon-open':'el-icon-turn-off'" :type="this.InfoData.state== 0? '':'danger'">{{this.InfoData.state== 0? ' 启用':' 停用'}}</el-tag>
				</div>
				<span class="mr40 username" v-if="$payType!=2">
					<span>接单状态：</span>
					<el-switch						
						v-model="switchState"
						active-color="#13ce66"
						inactive-color="#ff4949"
						@change="setTreatmentStatu">
					</el-switch>
				</span>
				<div class="lineY mr40  lineHeight" v-if="$payType!=2"></div>
				
				<el-button style="font-size:12px" size ="mini" type="danger" @click="logout">登出</el-button>
			</div>
		</div>
		<div class="bottomMain" v-if="!this.$route.meta.noNav">
			<div class="sideBox" :class="[{sideHiden:showSide==='hide',sideShow:showSide==='show'},setBackgroundColor($PayNameImg)]">
				<div v-for="(item,index) in nav" v-if="nav.length>0" :key="index" class="itemBox firstItemBox">
					<div class="navItem firstNav show pageNav"
						:class="{active:nowPath.includes(item.path) && item.meta.islink}"
						@click="gotopage(item,index,'showOne')">
						<span><span :class="item.meta.iconS"  style="color: #00a8ff;"></span> {{item.meta.title}}</span>
						<!-- <span><span style="color: #00a8ff;" :class="item.meta.iconS"></span> {{$t('main.menu.' + item.meta.title)}}</span> -->
						<span v-if="!item.meta.islink"
							:class="{'el-icon-arrow-right':!(show.showOne===index),'el-icon-arrow-down':(show.showOne === index)}"></span>
					</div>
					<div v-if="item.children && item.children.length > 0" class="childNav">
						<div v-for="(item2,index2) in item.children" :key="index2" class="itemBox">
							<div class="navItem twotNav pageNav"
								:class="{active:nowPath.includes(item2.path) && item2.meta.islink,ractive:show.showTwo===index2 && !item2.meta.islink,show:show.showOne === index}"
								@click="gotopage(item2,index2,'showTwo')">
								<span>{{item2.meta.title}}</span>
								<span v-if="!item2.meta.islink"
									:class="{'el-icon-arrow-right':show.showTwo!=index2}"></span>
							</div>

						</div>
					</div>
				</div>
			</div>
			<div class="threeDirectoryBox" :class="{noSide:showSide==='hide',hasSide:showSide==='show'}">
				<div v-if="threeDirectory.length > 0" class="threeDirectory">
					<div class="threeChildNav">
						<!-- <div v-if="threeDirectory.length>0" v-for="(item3,index3) in threeDirectory" :key="index3" class="itemBox" :class="{active:nowPath===item3.path }"> -->
						<div v-if="threeDirectory.length>0" v-for="(item3,index3) in threeDirectory" :key="index3" class="itemBox" :class="{active:nowPath===item3.path||nowPath=== item3.path+'sub'||nowPath=== item3.path+'sub2'}">
							<div class="navItem pageNav" @click="gotopage(item3,index3,'showThree')">
								{{item3.meta.title}}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="main-container"	:class="{noSide:showSide==='hide',hasSide:showSide==='show',hasThreeDic:threeDirectory.length > 0}">
				<!-- <div class="main-header"></div> -->
				<el-empty v-if="!isShowPage" description="您暂无权限查看">
					<el-button type="primary" @click="gohome">回首页</el-button>
				</el-empty>
				<router-view v-else/>
			</div>
		</div>
		<div class="loginMain" v-if="this.$route.meta.noNav">
			<!-- 登录 -->
			<router-view />
		</div>
	</div>
</template>
<!-- <script>
	window.onload = function() {
	　　alert('你好，欢迎光临');//各浏览器均正常弹出
	}
</script> -->
<script>
	import local from './util/local.js'
	export default {
		name: 'Home',
		components: {
			// HelloWorld
		},
		mounted() {
			let beginTime = 0; //开始时间
			let differTime = 0; //时间差
			window.onunload = function() {
				differTime = new Date().getTime() - beginTime;
				if (differTime <= 3) {
					// window.localStorage.removeItem("bpcusername");
					// window.localStorage.removeItem("bpctid");
				} else {}
			};
			window.onbeforeunload = function() {
				beginTime = new Date().getTime();
			};
		},
		data() {
			return {
				nav: [],
				show: {
					showOne: '',
					showTwo: '',
					showThree: ''
				},
				showSide: '', //是否显示左边导航
				nowPath: '',
				threeDirectory: [],
				ParentDirector: [],
				username: '',
				t: null,
				hasInterval: false,
				systemcount: {},
				InfoData: {},
				orderInfoData: [],
				switchState:false,
				apiNav:[],
				isShowPage:true,
				// showTirun:false,
			}
		},
		mounted() {
		    // 在 App.vue 挂载时初始化定时任务
		    this.startScheduledTask();
		},
		beforeDestroy() {
		    // 组件卸载前清除定时任务，防止内存泄漏
		    clearInterval(this.scheduledTask);
		},
		created() {
			this.username = local.get("bpcusername")
			// this.getNav()
			// this.enableList()
			if(!this.$route.meta.noNav){
				console.log(this.$route.meta.noNav,'=====',this.$route.name)
				this.enableList()
			}
			this.nowPath = this.$route.path
			this.getTreeDirectoru()
			this.getBreadCrumb()

		},
		methods: {
			handleOrderComplete(item) {
				
				let data = {
					id:item
				}
			  // 调用 API 标记订单已完成
			  	this.$api.closeNoticeOrder(data).then(res => {
			  		if (res.status == 1) {
						this.$notify.close();
			  		}else{
						this.$message({
							type: 'error',
							message:res.msg
						})
					}
			  	})
			},
			// 自动复制订单号的函数
			copyOrderNumber() {
			  const orderText = document.getElementById('order-number').innerText;
			  navigator.clipboard.writeText(orderText)
			    .then(() => alert('订单号已复制'))
			    .catch(err => console.error('复制失败:', err));
			},
			startScheduledTask() {
			  // 每 10 秒执行一次任务（可根据需要调整时间间隔）
			  this.scheduledTask = setInterval(() => {
				// 在这里添加你的定时任务逻辑
				if(this.hasInterval == true){
					this.performScheduledTask();
				}
				
			  }, 5000); // 每 10000 毫秒（10 秒）执行一次
			},
			performScheduledTask(){
				this.$api.getNoticeOrder({}).then(res => {
					if (res.status == 1) {
						this.orderInfoData = res.data
						this.$nextTick(() => {
						  const notification = this.$notify({
						    title: '订单通知',
						    dangerouslyUseHTMLString: true,
						    message: `
						      <div>
						        <p>金额: <strong>¥${this.orderInfoData.gold}</strong></p>
						        <p>账号: <strong>${this.orderInfoData.alipay_account}</strong></p>
						        <p>订单号: <strong id="order-number" style="cursor: pointer; color: blue;">${this.orderInfoData.trader_order_id}</strong></p>
						        <div>
						          <button id="order-complete" style="margin-right: 10px;">已处理完成</button>
						          <button id="force-close">强制关闭</button>
						        </div>
						      </div>
						    `,
						    customClass: 'custom-notification',
						    duration: 0
						  });
						
						  // 在通知框渲染后绑定事件
						  document.getElementById('order-number').onclick = this.copyOrderNumber;
						  document.getElementById('order-complete').onclick = () => {
						    this.handleOrderComplete(this.orderInfoData.id);
						    notification.close();  // 手动关闭通知框
						  };
						  document.getElementById('force-close').onclick = () => {
						    this.handleOrderComplete(this.orderInfoData.id);
						    notification.close();  // 手动关闭通知框
						  };
						});

						
						// this.$notify({
						//   title: '订单通知',
						//   dangerouslyUseHTMLString: true,
						//   message: `
						//     <div>
						//       <p>金额: <strong>¥${this.orderInfoData.gold}</strong></p>
						//       <p>账号: <strong>${this.orderInfoData.alipay_account}</strong></p>
						//       <p>
						//         订单号: <strong id="order-number" style="cursor: pointer; color: blue;" @click.stop="copyOrderNumber()">${this.orderInfoData.trader_order_id}</strong>
						//       </p>
						//       <div>
						//         <button style="margin-right: 10px;" @click.stop="handleOrderComplete(${this.orderInfoData.id})">已处理完成</button>
						//         <button @click.stop="handleOrderComplete(${this.orderInfoData.id})">强制关闭</button>
						//       </div>
						//     </div>
						//   `,
						//   onClick() {},
						//   onClose() {},
						//   customClass: 'custom-notification',
						//   duration: 0 // 设置为 0 防止自动关闭
						// });
					}
				})
			},
			getInfoTotal() {
				this.$api.infoTotal({}).then(res => {
					if (res.status == 1) {
						this.InfoData = res.data
						this.switchState = this.InfoData.manual_state == 1?true:false;
					}
				})
			},
			getTreeDirectoru() {
				for (let i = 0; i < this.nav.length; i++) {
					if (this.nav[i].children.length > 0) {
						for (let j = 0; j < this.nav[i].children.length; j++) {
							if (this.nowPath.includes(this.nav[i].children[j].path)) {
								if (this.nav[i].children[j].meta.leven === 1) {
									this.threeDirectory = this.nav[i].children[j].children
								}
							}
						}
					}
				}
			},
			getBreadCrumb() {
				for (let i = 0; i < this.nav.length; i++) {
					if (this.nowPath == this.nav[i].path && this.nav[i].meta.islink) {
						this.show.showOne = i
						this.ParentDirector = [{
							title: this.nav[i].meta.title,
							path: this.nav[i].path
						}]
						break
					} else {
						let navChild = this.nav[i].children;
						for (let j = 0; j < navChild.length; j++) {
							if (this.nowPath == navChild[j].path && navChild[j].children.length == 0) {
								this.show.showOne = i
								this.show.showTwo = j
								this.ParentDirector = [{
									title: this.nav[i].meta.title,
									path: this.nav[i].path
								}, {
									title: navChild[j].meta.title,
									path: navChild[j].path
								}]
								break
							} else {
								let threeChild = navChild[j].children;
								for (let k = 0; k < threeChild.length; k++) {
									if (this.nowPath == threeChild[k].path) {
										this.show.showOne = i
										this.show.showTwo = j
										this.ParentDirector = [{
											title: this.nav[i].meta.title,
											path: this.nav[i].path
										}, {
											title: navChild[j].meta.title,
											path: navChild[j].path
										}, {
											title: threeChild[k].meta.title,
											path: threeChild[k].path
										}]
										break
									}
								}
							}
						}
					}
				}
			},
			getNav(apiNav) {
				
				let telephone = this.$local.get('telephone')
				let tid = local.get("bpctid")
				let payList = this.$local.get('payList');
				if (payList) {
					this.$router.options.routes.forEach(item => {
						if (item.meta.isPayType) {
							payList.forEach(payItem => {
								if (item.meta.fee_type == payItem.id) {
									item.directory = true;
								}
							})
						}
					})
				}
				//判断该支付方式页面目录是否显示 pay_type
				this.$router.options.routes.forEach(item => {
					if(item.meta.pay_type){
						item.directory = false
						item.meta.islink = false						
					}
					apiNav.forEach(apiItem => {
						if(item.meta.pay_type == apiItem.pay_type){
							item.directory = true
							item.meta.islink = true
						}
					})
					
				})


				if(this.$payType == 2){
					this.$router.options.routes.forEach(item => {
						if (item.meta.appType&&item.meta.appType!=2) {
							item.directory = false
						}
					})
				}
				if(telephone){
					this.$router.options.routes.forEach(item => {
						if (item.directory && !item.meta.subAccount) {
							item.directory = false
						}else if(item.directory && item.meta.subAccount){
							item.directory = true
						}
					})
				}
				
				let routes = this.$router.options.routes;
				let navAll = routes.filter(item => {
					if (item.directory) {
						return item
					}
				})
				// console.log(navAll,'navAll')
				let navArr = []
				for (let i = 0; i < navAll.length; i++) {
					if (navAll[i].meta.parentId === 0) {
						navAll[i].children = []
						navArr.push(navAll[i])
						navAll.splice(i, 1)
						i = i - 1
					}
				}
				for (let i = 0; i < navAll.length; i++) {
					if (navAll[i].meta.parentId != 0 && navAll[i].meta.leven === 1) {
						for (let j = 0; j < navArr.length; j++) {
							if (navAll[i].meta.parentId === navArr[j].meta.id) {
								navAll[i].children = []
								navArr[j].children.push(navAll[i])
							}
						}
					}
				}
				// 三级目录
				for (let i = 0; i < navAll.length; i++) {
					if (navAll[i].meta.parentId != 0 && navAll[i].meta.leven === 2) {
						for (let j = 0; j < navArr.length; j++) {
							for (let k = 0; k < navArr[j].children.length; k++) {
								if (navAll[i].meta.parentId === navArr[j].children[k].meta.id) {
									navArr[j].children[k].children.push(navAll[i])
								}
							}
						}
					}
				}
				this.nav = navArr
				// console.log(navAll, navArr)
			},
			gohome(){
				this.$router.push({
					path: '/allIndex'
				})
			},
			gotopage(item, index, showNum) {
				if (this.showSide == 'show') {
					this.showSide = ""
				}
				this.showChildren(index, showNum, item.meta.islink)
				if (this.$route.path === item.path && item.meta.islink) {
					this.$router.push({
						path: '/replace'
					})
					return
				}
				let isNowPath = false
				if (item.meta.islink) {
					if (item.path == '/passage') {
						this.$router.options.routes.forEach((itemr, indexr) => {
							if (itemr.name == "PassageCard") {
								if (itemr.directory) {
									isNowPath = true;
									this.$router.push(itemr.path)
								}
							} else if (!isNowPath) {
								if (itemr.meta.isPayType) {
									if (itemr.directory) {
										this.$router.push(itemr.path)
										isNowPath = true
									}
								}
							}
						})
						if (!isNowPath) {
							this.$message({
								type: 'warning',
								message: "暂时没有开通支付通道"
							});
						}
					} else {
						this.$router.push(item.path)
					}
				}
				if (item.meta.leven === 1) {
					this.threeDirectory = item.children
				}
			},
			toggleClick() {
				if (this.showSide === 'hide') {
					this.showSide = 'show'
				} else {
					this.showSide = 'hide'
				}
			},
			showChildren(index, showNum, islink) {
				if (showNum === 'showOne') {
					this.show.showTwo = ''
				}
				if (this.show[showNum] === index) {
					if (!islink) {
						this.show[showNum] = ''
					}
				} else {
					this.show[showNum] = index
				}
			},
			logout() {
				// this.$local.remove()
				this.$confirm('确认退出?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$api.logout({}).then(res => {
						// console.log(res)
						if (res.status === 1) {
							this.$local.remove('bpctid')
							this.$local.remove('bpcusername')
							this.$local.remove('payList')
							this.$router.push('/')
						} else {
							this.$message({
								type: 'info',
								message: res.msg
							});
						}

					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			getData() {
				this.t = setInterval(() => {
					this.getNoticeCount()
				}, 30000)
			},
			getNoticeCount() {
				this.$api.getNoticeCount({}).then(res => {
					if (res.status === 1) {
						this.systemcount = res.data
					} else {
						this.$message({
							type: "error",
							message: res.msg
						})
					}
				})

			},
			
			gotopageSetTrueQuota() {
				this.$router.push({
					path: '/setTrueQuota',
				})
				if (this.$route.path === '/setTrueQuota') {
					location.reload();
				}
			},
			gotopageOrderPay() {
				this.$router.push({
					path: '/orderPay',
					query: {
						state: 2
					}
				})
				if (this.$route.path === '/orderPay') {
					location.reload();
				}
			},
			gotopagePassage() {
				this.$router.push({
					path: '/passageCard',
					query: {
						state: 2
					}
				})
				if (this.$route.path === '/passageCard') {
					location.reload();
				}
			},
			gotopageWithdraw() {
				this.$router.push({
					path: '/withdraw',
					query: {
						state: 3
					}
				})
				if (this.$route.path === '/withdraw') {
					location.reload();
				}
			},
			
			showDataAll() {
				if (this.$route.path === 'allIndex') {
					this.$router.push({
						path: '/replace'
					})
					return
				}
				this.threeDirectory = []
				this.$router.push('/allIndex')
			},
			setTreatmentStatu(val){
				// console.log(val)
				if(val){
					this.switchState = false
				}
				else{
					this.switchState = true
				}
				// console.log(val,'=====')
				let stateVal = val ? 1: 0
				this.$confirm(`确认${stateVal == 1?'开启':'停用'}接单吗`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						state:stateVal
					}
					// this.switchState = this.InfoData.manual_state == 1?true:false
					this.$api.setManualState(data).then(res => {
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							});
							this.switchState = val
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							});						
							// this.InfoData.manual_state = stateVal == 1?0:1
						}
						// this.switchState = this.InfoData.manual_state == 1?true:false
						this.getInfoTotal() 
					})
				}).catch(() => {
					// this.InfoData.manual_state = val == 1?0:1
					this.$message({
						type: 'info',
						message: '已取消操作'
					});
				});
				
			},
			setBackgroundColor(name){
				if(name == 'wanding'){
					return "wanding"
				}else if(name == 'laike-YL'){
					return "laike-YL"
				}else{
					return "default"
				}
				
			},
			enableList(){
				 this.$api.enableList({}).then(res => {
					if (res.status === 1) {
						this.apiNav = res.data
					} else {
						this.apiNav = []		
					}
					this.getNav(this.apiNav)
				}).catch(err => {
					this.apiNav = []
					this.getNav(this.apiNav)
				})
			}
		},
		watch: {
			$route(to, from) {
				this.username = local.get("bpcusername")
				this.nowPath = this.$route.path
				this.getBreadCrumb()
				this.getTreeDirectoru()
				if (this.nowPath == "/login" || this.nowPath == "/googleVerification") {
					this.hasInterval = false
					clearInterval(this.t)
				} else {
					this.hasInterval = true
				}
				if(to.meta.islink ){
					this.isShowPage = true	
				}else{
					this.isShowPage = false
				}
			
			},
			hasInterval(val, old) {
				if (this.hasInterval) {
					if(this.$payType !=2){						
						this.getNoticeCount()
						this.getData()
					}
					this.getInfoTotal()

				}
			},
			apiNav(val,old){
				// console.log('val',val,old)
				let nowRoute = this.$route
				// console.log('===',nowRoute)
				if(nowRoute.meta.islink){
					this.isShowPage = true	
				}else{
					this.isShowPage = false
				}
			}
		}
	}
</script>
<style lang="scss">
	@import '@/style/variables.scss';

	* {
		padding: 0;
		margin: 0;
	}
	.default{
		background: #e9ffeb;  /* fallback for old browsers */
	}
	.wanding{
		background: #16222A;  /* fallback for old browsers */
		background: -webkit-linear-gradient(to bottom, #3A6073, #16222A);  /* Chrome 10-25, Safari 5.1-6 */
		background: linear-gradient(to bottom, #3A6073, #16222A); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

	}
	.laike-YL{
		background: #16222A;  /* fallback for old browsers */
		background: -webkit-linear-gradient(to top, #3A6073, #16222A);  /* Chrome 10-25, Safari 5.1-6 */
		background: linear-gradient(to top, #3A6073, #16222A); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

	}
	
	.usdtCss{
	   color: #909399;
	   font-size: xx-small;
   	}

	#app {
		// font-family: Avenir, Helvetica, Arial, sans-serif;
		font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		// text-align: center;
		color: #303133;
		font-size: 16px;
	}

	#nav {
		padding: 30px;
	}

	.topNav {
		height: 45px;
		// background-color: $menuBg;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 99;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-right: 20px;
		.manualSelect{
			width: 80px;
		}
		.lineHeight{
			height: 30px;
		}
		// color: $navColor;
		.username {
			color: $navColor
		}

		.systemSet {
			color: #fff;
			cursor: pointer
		}
	}

	.hamburger {
		display: inline-block;
		vertical-align: middle;
		width: 20px;
		height: 20px;
	}

	.hamburger .icon {
		background-color: transparent;
		border-bottom: 20px solid $warning;
		border-right: 20px solid transparent;
		transform: rotate(45deg);
	}

	.hideSidebar .hamburger .icon {
		background-color: transparent;
		border-bottom: 20px solid $warning;
		border-right: 20px solid transparent;
		transform: rotate(-135deg);
	}

	.hamburger.is-active {
		transform: rotate(180deg);
	}

	.sideBox {
		color: $navColor;
		text-align: left;


		.itemBox {
			padding-left: 20px;
			cursor: pointer;



			&.firstItemBox {
				padding-left: 0;

			}

			.childNav {
				// background-color: $subMenuBg;
				// font-size: 18px;
			}

			.navItem {
				padding: 12px 10px;
				display: none;

				align-items: center;
				justify-content: space-between;

				&.twotNav {
					font-size: 15px;
					color: $navSubColor;
					padding-left: 20px;
				}

				&.firstNav {
					border-bottom: 1px solid rgba(107, 108, 109, 0.19);

				}

				&.show {
					display: flex;
				}

				&.active {
					color: $primary;
				}

				&.ractive {
					color: #f5f5f5;
				}
			}
		}

	}

	.threeDirectoryBox {
		.threeChildNav {
			display: flex;
			height: 40px;
			background-color: $threeNavbackColor;
			align-items: center;
			box-sizing: border-box;
			margin: 10px 10px 0;
			border: 1px solid #ddd;

			.itemBox {
				// margin-right: 20px;
				padding: 0 10px;
				height: 100%;
				display: flex;
				align-items: center;
				border-bottom: solid 2px rgba(0, 0, 0, 0);
				cursor: pointer;
				margin-bottom: -1px;

				&.active {
					color: $primary;
					background: #fff;
					border-left: solid 1px $tableBorderColor;
					border-right: solid 1px $tableBorderColor;
				}

				&:first-child.active {
					border-left: none
				}
			}
		}
	}

	.activeClass {
		color: $warning;
	}

	.loginMain {
		min-height: 100vh;
	}
	  .el-table {
	    .cell {
	      font-size: 0.75rem; /* 这里的16px可以根据需要修改为其他大小 */
	    }
	  }
</style>
