import request from '@/util/request'
export default{
	//登录
	login(data){
		return request({url: '/carder/login/DoLogin',method: 'post',data})		
	},	
	//登出
	logout(data){
		return request({url: '/carder/login/logout',method: 'post',data})		
	},	
	//谷歌验证检查
	validateGcode(data){
		return request({url: '/carder/login/validateGcode',method: 'post',data})		
	},
	//谷歌验证码绑定
	bingGoogle(data){
		return request({url: '/carder/login/bindGoogle',method: 'post',data})		
	},
	
	//修改密码
	setMannerPassword(data){
		return request({url: 'carder/user/setPassword',method: 'post',data})		
	},	
	//代付订单
	orderOutList(data){
		return request({url: 'carder/order/orderOutList',method: 'post',data})		
	},
	//领取手动代付订单
	orderOutRequest(data){
		return request({url: 'carder/order/orderOutRequest',method: 'post',data})		
	},	
	//完成手动代付订单
	orderOutComplete(data){
		return request({url: 'carder/order/orderOutComplete',method: 'post',data})		
	},	
	
	//手动代付订单关闭原因获取
	orderOutState(data){
		return request({url: 'carder/order/orderOutState',method: 'post',data})		
	},	
	//关闭手动代付订单
	orderOutClose(data){
		return request({url: 'carder/order/orderOutClose',method: 'post',data})		
	},	
	
	//充值订单
	orderInList(data){
		return request({url: 'carder/order/orderInList',method: 'post',data})		
	},	
	
	//跑量统计
	cardListOrder(data){
		return request({url: 'carder/order/cardList',method: 'post',data})		
	},
	//通道管理 支付类型列表
	payTypeAll(data){
		return request({url: 'carder/pay_type/all',method: 'post',data})		
	},	
	//通道管理 支付类型列表
	bankList(data){
		return request({url: 'carder/user/bankList',method: 'post',data})		
	},	
	//卡列表
	/* $page = input('page', 1);
	$size = input('size', Config::get('paginate.list_rows'));
	$name = input('name'); //卡姓名
	$pay_type = input('pay_type'); //原因返回给你的 */
	cardList(data){
		return request({url: 'carder/pay_type/cardList',method: 'post',data})		
	},
	//卡详情
	cardInfo(data){
		return request({url: 'carder/user/cardInfo',method: 'post',data})		
	},
	//通道详情
	 // public function info()
	 //    {
	 //        $pay_type = input('pay_type');//通道ID
	payTypeInfo(data){
		return request({url: '/carder/pay_type/info',method: 'post',data})		
	},
	//新增卡
	editCard(data){
		return request({url: 'carder/user/editCard',method: 'post',data})		
	},
	//编辑卡
	addCard(data){
		return request({url: 'carder/user/addCard',method: 'post',data})		
	},
	
	//获取测试金额
	checkGold(data){
		return request({url: 'carder/order/checkGold',method: 'post',data})		
	},
	//创建充值订单
	addOrderIn(data){
		return request({url: 'carder/order/addOrderIn',method: 'post',data})		
	},
	//创建代付订单
	addOrderOut(data){
		return request({url: 'carder/order/addOrderOut',method: 'post',data})		
	},
	//店铺列表
	storeList(data){
		return request({url: 'carder/user/storeList',method: 'post',data})		
	},
	//店铺详情
	storeInfo(data){
		return request({url: 'carder/user/storeInfo',method: 'post',data})		
	},
	//编辑店铺
	editStore(data){
		return request({url: 'carder/user/editStore',method: 'post',data})		
	},
	/**
		* 添加淘宝店铺
		public function addStore()
		{
			$store_name = trim(input('store_name'));
			$state = input('state');// 状态 0-停用 1-启用
	*/
	addStore(data){
		return request({url: 'carder/user/addStore',method: 'post',data})		
	},
	// 获取当前店铺金额
	getStoreMoneyGroup(data){
		return request({url: 'carder/user/getStoreMoneyGroup',method: 'post',data})		
	},
	
	/**
	     * 店铺二维码列表
	    public function storeQrList()
	    {
	        $size = input('size', Config::get('paginate.list_rows'));
	        $store_id = input('store_id');
	        $type = input('type');// 类型 1-待使用 2-已使用 3-已过期
	 */
	storeQrList(data){
		return request({url: 'carder/user/storeQrList',method: 'post',data})		
	},
	//二维码停用
	setStoreQrState(data){
		return request({url: 'carder/user/setStoreQrState',method: 'post',data})		
	},
	//删除店铺收款码
	deleteStoreQr(data){
		return request({url: 'carder/user/deleteStoreQr',method: 'post',data})		
	},
	//上传二维码
	uploadStoreQrAndAdd(data){
		return request({url: 'carder/user/uploadStoreQrAndAdd',method: 'post',data})		
	},
	//编辑店铺收款码
	editStoreQr(data){
		return request({url: 'carder/user/editStoreQr',method: 'post',data})		
	},
	//提现订单
	mentionList(data){
		return request({url: 'carder/order/mentionList',method: 'post',data})		
	},
	//报损记录
	lossList(data){
		return request({url: 'carder/order/lossList',method: 'post',data})		
	},
	
	//我的粉丝
	fans(data){
		return request({url: 'carder/user/fans',method: 'post',data})		
	},
	//我的粉丝
	infoTotal(data){
		return request({url: 'carder/user/infoTotal',method: 'post',data,allowRepetition:true})		
	},
	//我的粉丝
	feeList(data){
		return request({url: 'carder/user/feeList',method: 'post',data})		
	},
	//我的粉丝详情
	info(data){
		return request({url: 'carder/user/info',method: 'post',data})		
	},
	
	//我的粉丝详情
	addFans(data){
		return request({url: 'carder/user/addFans',method: 'post',data})		
	},
	//我的粉丝详情设置费率
	setFee(data){
		return request({url: 'carder/user/setFee',method: 'post',data})		
	},
	//个人信息设置电话
	setPhone(data){
		return request({url: 'carder/user/setPhone',method: 'post',data})		
	},
	//修改密码
	setPassword(data){
		return request({url: 'carder/user/setPassword',method: 'post',data})		
	},
	//待修正余额卡列表
	cardTrueQuotaList(data){
		return request({url: 'carder/user/cardTrueQuotaList',method: 'post',data})		
	},
	//修正余额
	setCardTrueQuota(data){
		return request({url: 'carder/user/setCardTrueQuota',method: 'post',data})		
	},
	//提现卡
	mentionCardInfo(data){
		return request({url: 'carder/user/mentionCardInfo',method: 'post',data})		
	},
	//新增提现卡
	addMentionCard(data){
		return request({url: 'carder/user/addMentionCard',method: 'post',data})		
	},
	//编辑提现卡
	editMentionCard(data){
		return request({url: 'carder/user/editMentionCard',method: 'post',data})		
	},
	//提现申请
	mentionMoney(data){
		return request({url: 'carder/user/mentionMoney',method: 'post',data})		
	},
	//待处理数据数量
	getNoticeCount(data){
		return request({url: 'carder/order/getNoticeCount',method: 'post',data,allowRepetition:true})		
	},
	//待处理数据数量
	setManualState(data){
		return request({url: 'carder/user/setManualState',method: 'post',data})		
	},
	
	//商家充值谷歌验证码
	// setMemberGoogleKey(data){
	// 	return request({url: 'manager/member/setGoogleKey',method: 'post',data,allowRepetition:true})		
	// },
	//支付宝扫码加好友通道  支付宝账号列表
	alipayList(data){
		return request({url: 'carder/user/alipayList',method: 'post',data})		
	},

	addAlipay(data){
		return request({url: 'carder/user/addAlipay',method: 'post',data})		
	},
	editAlipay(data){
		return request({url: 'carder/user/editAlipay',method: 'post',data})		
	},
	setAlipayState(data){
		return request({url: 'carder/user/setAlipayState',method: 'post',data})		
	},

	ensureOrder(data){
		return request({url: 'carder/order/ensureOrder',method: 'post',data})		
	},
	//上传图片
	uploadQrcode(data){
		return request({url: 'carder/user/uploadQrcode',method: 'post',data})		
	},
	orderAlipayList(data){
		return request({url: 'carder/order/alipayList',method: 'post',data})		
	},
	numberList(data){
		return request({url: 'carder/order/numberList',method: 'post',data})		
	},
	// 新增聚合码
	addAgCode(data){
		return request({url: '/carder/user/addAgCode',method: 'post',data})		
	},
	// 编辑聚合码
	editAgCode(data){
		return request({url: '/carder/user/editAgCode',method: 'post',data})		
	},
	// 聚合码跑量统计
	agCodeList(data){
		return request({url: '/carder/order/agCodeList',method: 'post',data})		
	},
	// 充值订单补单
	replenishment(data){
		return request({url: '/carder/order/replenishment',method: 'post',data})		
	},
	// 充值订单补单
	replenishmentState(data){
		return request({url: '/carder/order/replenishmentState',method: 'post',data})		
	},
	// 充值订单补单
	deleteAlipay(data){
		return request({url: '/carder/user/deleteAlipay',method: 'post',data})		
	},
	// 可用支付类型查询
	enableList(data){
		return request({url: '/carder/pay_type/enableList',method: 'post',data})		
	},
	// 驳回订单
	closeOrder(data){
		return request({url: '/carder/order/closeOrder',method: 'post',data})		
	},
	// 驳回原因查询
	closeReason(data){
		return request({url: '/carder/order/closeReason',method: 'post',data})		
	},
	// 驳回原因查询
	setReason(data){
		return request({url: '/carder/order/setReason',method: 'post',data})		
	},
	// 下级跑量查询
	fansCount(data){
		return request({url: '/carder/order/fansCount',method: 'post',data})		
	},
	// 所有支付类型查询
	paylistIn(data){
		return request({url: '/carder/pay_type/listIn',method: 'post',data})		
	},
	// /carder/user/TransferAdvanceMoney  - 划转额度接口
	// carder_id - 下级搬砖工ID
	// amount - 划转额度
	TransferAdvanceMoney(data){
		return request({url: '/carder/user/TransferAdvanceMoney',method: 'post',data})		
	},
	// /carder/order/orderOutSnatchList  - 代付抢单的订单列表
	// min_gold: String(this.check_min_gold),
	// max_gold: String(this.check_max_gold),
	orderOutSnatchList(data){
		return request({url: '/carder/order/orderOutSnatchList',method: 'post',data})		
	},
	// /carder/order/orderOutSnatch  - 代付抢单提交接口
	// id
	orderOutSnatch(data){
		return request({url: '/carder/order/orderOutSnatchAlipay',method: 'post',data})		
	},
	// /carder/order/orderOutSnatchReject  - 代付订单驳回接口
	// 参数："id":  子订单ID,
    // "note": "驳回原因，参考关闭订单",
	orderOutSnatchReject(data){
		return request({url: '/carder/order/orderOutSnatchReject',method: 'post',data})		
	},
	// /carder/user/getHd  - 获取话单
	// 参数："parvalue":  金额,
	getHd(data){
		return request({url: '/carder/user/getHd',method: 'post',data})		
	},
	// /carder/Mobile/addMobile  - 新增机位
	addMobile(data){
		return request({url: '/carder/Mobile/addMobile',method: 'post',data})		
	},
	// /carder/Mobile/delMobile  - 删除机位
	delMobile(data){
		return request({url: '/carder/Mobile/delMobile',method: 'post',data})		
	},
	// /carder/Mobile/setMobile  - 修改机位
	setMobile(data){
		return request({url: '/carder/Mobile/setMobile',method: 'post',data})		
	},
	// /carder/Mobile/getMobiles  - 查询机位
	getMobiles(data){
		return request({url: '/carder/Mobile/getMobiles',method: 'post',data})		
	},
	// /carder/Mobile/batchDelMobile  - 批量删除机位
	batchDelMobile(data){
		return request({url: '/carder/Mobile/batchDelMobile',method: 'post',data})		
	},
	// /carder/order/getNoticeOrder  - 获取需要通知处理回调的订单.
	getNoticeOrder(data){
		return request({url: '/carder/order/getNoticeOrder',method: 'post',data})		
	},
	// /carder/order/closeNoticeOrder  - 获取需要通知处理回调的订单.
	closeNoticeOrder(data){
		return request({url: '/carder/order/closeNoticeOrder',method: 'post',data})		
	},
	// carder/user/getTbSqUrl  - 获取授权二维码.
	getTbSqUrl(data){
		return request({url: '/carder/user/getTbSqUrl',method: 'post',data})		
	},
	// /carder/user/getTbCk  获取cookie
	getTbCk(data){
		return request({url: '/carder/user/getTbCk',method: 'post',data})		
	},
	//carder/user/getTbCkBySms

	getTbCkBySms(data){
		return request({url: '/carder/user/getTbCkBySms',method: 'post',data})		
	},
	//carder/carder/getTypeDetail 余额流水页 单选框 order_type 字段的返回值
	getTypeDetail(data){
		return request({url: '/carder/carder/getTypeDetail',method: 'post',data})		
	},
	///carder/carder/moneyChangeList  余额流水列表
	moneyChangeList(data){
		return request({url: '/carder/carder/moneyChangeList',method: 'post',data})		
	},
	//carder/order/getOrderPayNote  抢单释放
	getOrderPayNote(data){
		return request({url: '/carder/order/getOrderPayNote',method: 'post',data})		
	},
	//carder/order/carderReportList 跑量统计
	carderReportList(data){
		return request({url: '/carder/order/carderReportList',method: 'post',data})		
	},
	//关闭手动代付订单
	orderOutStateClose(data){
		return request({url: 'carder/order/orderOutStateClose',method: 'post',data})		
	},	
	//通道管理批量删除
	batchDeleteAlipays(data){
		return request({url: 'carder/user/batchDeleteAlipays',method: 'post',data})		
	},
	//通道管理批量启用 停用 batchState
	batchState(data){
		return request({url: 'carder/user/batchState',method: 'post',data})		
	},
	//目录 enableList
	enableList(data){
		return request({url: 'carder/pay_type/enableList',method: 'post',data,allowRepetition:true})		
	},
}