<template>
	<div>
		<div class="cardBox accountCard">			
			<div class="letterSpacing">账户</div>
			<div class="flexX flexcenter flexJustifyStart flexWrap mt20 ">
				<div class="flexX flexcenter flexJustifyStart flexWrap  balance-box ">
					<div class="box-card backinfoDeep">
							<div slot="header" class="clearfix  cardHeader ">
								<span class="mr10 letterSpacing">余额</span><span class="letterSpacing fs20 cdanger">转账ID:</span><span class="fs20 cdanger">{{data.id}}</span>
							</div>
							<div class="cardCon">
								<div class="flexX flexBetween text flexcenter">
									<!-- <div><span class="icon iconfont fs40">&#xe612;</span></div> -->
									<div><span class="icon iconfont fs40">&#xe6c2;</span></div>
									<div class="fs20">{{data.quota}}元</div>
									<el-button size="mini" type="danger"  @click="openTransferAdvanceMoney">转分</el-button>
									<!-- <el-tag  @click="openTransferAdvanceMoney">转分</el-tag> -->
									<!-- <div class="pointer" @click="openTransferAdvanceMoney">转分</div> -->
								</div>
							</div>
						</div>
				</div>
				<div class="box-card backinfo">
					<div slot="header" class="clearfix  cardHeader">
						<span class="letterSpacing">总余额</span>
					</div>
					<div class="cardCon">
						<div class="flexX flexBetween text  flexcenter">
							<div><span class="icon iconfont fs40">&#xe6c2;</span></div>
							<div class="fs20">{{Number(data.advance_money) + Number(data.quota)}}元</div>
						</div>
					</div>
				</div>
				<div class="box-card backyellow">
					<div slot="header" class="clearfix  cardHeader">
						<span class="letterSpacing">押金</span>
					</div>
					<div class="cardCon">
						<div class="flexX flexBetween text flexcenter">
							<div><span class="icon iconfont fs40">&#xe673;</span></div>
							<div class="fs20">{{data.advance_money }}</div>
						</div>
					</div>
				</div>

				<!-- <div class="box-card backsuccess">
					<div slot="header" class="clearfix  cardHeader">
						<span>递增金额</span>
					</div>
					<div class="flexX flexBetween text cardCon flexcenter">
						<div><span class="icon iconfont fs40">&#xe671;</span></div>
						<div class="fs20">{{data.today_can_money_in}}</div>
					</div>
				</div>
				<div class="box-card backprimary">
					<div slot="header" class="clearfix  cardHeader">
						<span>递减金额</span>
					</div>
					<div class="flexX flexBetween text cardCon flexcenter">
						<div><span class="icon iconfont fs40">&#xe673;</span></div>
						<div class="fs20">{{data.today_yj}}</div>
					</div>
				</div>
				 -->
			
			</div>
		</div>
		<el-divider></el-divider>
		<div class="cardBox">			
			<div>今日 </div>
			<div class="flexX flexcenter flexJustifyStart flexWrap mt20 ">
				<div class="box-card backsuccess">
					<div slot="header" class="clearfix  cardHeader">
						<span class="letterSpacing">代收</span>
					</div>
					<div class="cardCon">
						<div class="flexX flexBetween text  card-con-tow flexcenter">
							<div class="letterSpacing">笔数</div>
							<div class="fs20">{{data.ds_success_count}}笔</div>
						</div>
						<div class="flexX flexBetween text  card-con-tow flexcenter">
							<div class="letterSpacing">金额</div>
							<div class="fs20">{{data.ds_success_money}}元</div>
						</div>
						<div class="flexX flexBetween text  card-con-tow flexcenter">
							<div class="letterSpacing">佣金</div>
							<div class="fs20">{{data.carder_yj}}元</div>
						</div>
					</div>
				</div>

				<div class="box-card backperpole">
					<div slot="header" class="clearfix  cardHeader">
						<span class="letterSpacing">团队</span>
					</div>
					<div class="cardCon">
						<div class="flexX flexBetween text  card-con-tow flexcenter">
							<div class="letterSpacing">笔数</div>
							<div class="fs20">{{data.fans_ds_success_count}}笔</div>
						</div>
						<div class="flexX flexBetween text  card-con-tow flexcenter">
							<div class="letterSpacing">金额</div>
							<div class="fs20">{{data.fans_ds_success_money}}元</div>
						</div>						
						<div class="flexX flexBetween text  card-con-tow flexcenter">
							<div class="letterSpacing">提成</div>
							<div class="fs20">{{data.carder_tc}}元</div>
						</div>
					</div>
				</div>

				<div class="box-card backdanger">
					<div slot="header" class="clearfix  cardHeader">
						<span class="letterSpacing">代付</span>
					</div>
					<div class="cardCon">
						<div class="flexX flexBetween text  card-con-tow flexcenter">
							<div class="letterSpacing">笔数</div>
							<div class="fs20">{{data.df_success_count}}笔</div>
						</div>
						<div class="flexX flexBetween text  card-con-tow flexcenter">
							<div class="letterSpacing">金额</div>
							<div class="fs20">{{data.df_success_money}}元</div>
						</div>
					</div>
				</div>
				
			
			</div>
		</div>
		
		<el-divider></el-divider>
		<div class="cardBox mb20">			
			<div>昨日 </div>
			<div class="flexX flexcenter flexJustifyStart flexWrap mt20 ">
				<div class="box-card backsuccess">
					<div slot="header" class="clearfix  cardHeader">
						<span class="letterSpacing">代收</span>
					</div>
					<div class="cardCon">
						<div class="flexX flexBetween text  card-con-tow flexcenter">
							<div class="letterSpacing">笔数</div>
							<div class="fs20">{{yesterdayData.ds_success_count}}笔</div>
						</div>
						<div class="flexX flexBetween text  card-con-tow flexcenter">
							<div class="letterSpacing">金额</div>
							<div class="fs20">{{yesterdayData.ds_success_money}}元</div>
						</div>
						<div class="flexX flexBetween text  card-con-tow flexcenter">
							<div class="letterSpacing">佣金</div>
							<div class="fs20">{{yesterdayData.carder_yj}}元</div>
						</div>
					</div>
				</div>

				<div class="box-card backperpole">
					<div slot="header" class="clearfix  cardHeader">
						<span class="letterSpacing">团队</span>
					</div>
					<div class="cardCon">
						<div class="flexX flexBetween text  card-con-tow flexcenter">
							<div class="letterSpacing">笔数</div>
							<div class="fs20">{{yesterdayData.fans_ds_success_count}}笔</div>
						</div>
						<div class="flexX flexBetween text  card-con-tow flexcenter">
							<div class="letterSpacing">金额</div>
							<div class="fs20">{{yesterdayData.fans_ds_success_money}}元</div>
						</div>						
						<div class="flexX flexBetween text  card-con-tow flexcenter">
							<div class="letterSpacing">提成</div>
							<div class="fs20">{{yesterdayData.carder_tc}}元</div>
						</div>
					</div>
				</div>

				<div class="box-card backdanger">
					<div slot="header" class="clearfix  cardHeader">
						<span class="letterSpacing">代付</span>
					</div>
					<div class="cardCon">
						<div class="flexX flexBetween text  card-con-tow flexcenter">
							<div class="letterSpacing">笔数</div>
							<div class="fs20">{{yesterdayData.df_success_count}}笔</div>
						</div>
						<div class="flexX flexBetween text  card-con-tow flexcenter">
							<div class="letterSpacing">金额</div>
							<div class="fs20">{{yesterdayData.df_success_money}}元</div>
						</div>
					</div>
				</div>
				
			
			</div>
		</div>
		<!-- 转分弹窗 -->
		<el-dialog width="500px" center top="150px" title="转分" :visible.sync="dialogVisible" @close="close" :close-on-click-modal="false">
			<div class="formBoxbd" v-loading="loading">
				<div>
					
					<el-form label-position="right" label-width="120px">						
						<el-form-item label="金额">
							<el-input v-model="transferAdvanceMoney.amount" placeholder="请输入金额"></el-input>
						</el-form-item>
						<el-form-item label="收款id">
							<el-input v-model="transferAdvanceMoney.carder_id" placeholder="请输入收款id"></el-input>
						</el-form-item>
						<el-form-item label="姓名">
							<el-input v-model="transferAdvanceMoney.name" placeholder="请输入姓名"></el-input>
						</el-form-item>						
						<el-form-item label="备注">
							<el-input v-model="transferAdvanceMoney.note" placeholder="请输入备注"></el-input>
						</el-form-item>					
						<el-form-item label="谷歌验证码">
							<el-input v-model="transferAdvanceMoney.code" placeholder="请输入谷歌验证码"></el-input>
						</el-form-item>
					</el-form>
				</div>
				<div class="textCenter">
					<el-button type="primary" @click="onSubmit">确定</el-button>
				</div>
			</div>
		</el-dialog>
		
	</div>
</template>

<script>
	import * as echarts from 'echarts'
	export default {
		data() {
			let today = this.$util.timestampToTime(new Date().getTime(), true)
			let yesterday = this.$util.getLastDate(today,-1)
			let todayParams = {
				start_time:today+' 00:00:00',
				end_time:today+' 23:59:59'
			}
			let yesterdayParams = {
				start_time:yesterday+' 00:00:00',
				end_time:yesterday+' 23:59:59'
			}
			return {
				todayParams:todayParams,
				yesterdayParams:yesterdayParams,
				data: {},//今日数据
				yesterdayData: {},//昨日数据
				switchWitdh: 35,
				jieDanState:false,
				dialogVisible:false,
				//转分数据
				loading : false,
				transferAdvanceMoney:{
					carder_id:'',
					amount:'',
					name:'',
					note:'',
					code:'',
				}
			}
		},
		created() {
			
			this.getData(this.todayParams,true)
			this.getData(this.yesterdayParams,false)
		},
		methods: {
			getData(data,isToday) {
				this.$api.infoTotal(data).then(res => {
					if (res.status == 1) {
						if(isToday){
							this.data = res.data
						}else{
							this.yesterdayData = res.data
						}
						
					}
				})
			},
			openTransferAdvanceMoney(){
				this.dialogVisible = true ;
			},
			onSubmit(){
			
				if(this.transferAdvanceMoney.amount == ''){
					this.$message({
						message: '请输入金额',
						type: 'error'
					});
					return
				}
				let regex = /^\d+(\.\d{1,3})?$/
				console.log('金额真正匹配',regex.test(this.transferAdvanceMoney.amount))
				if( !regex.test(this.transferAdvanceMoney.amount)){
					this.$message({
						message: '请输入正确金额',
						type: 'error'
					});
					return
				}
				//信息校验
				if(this.transferAdvanceMoney.carder_id == ''){
					this.$message({
						type: 'error',
						message: '请输入收款id'
					})
					return
				}
				if(this.transferAdvanceMoney.name == ''){
					this.$message({
						message: '请输入姓名',
						type: 'error'
					});
					return
				}
				if(this.transferAdvanceMoney.code == ''){
					this.$message({
						message: '请输入谷歌验证码',
						type: 'error'
					});
					return
				}
				this.loading = true ;
				// this.transferAdvanceMoney.carder_id = Number(this.transferAdvanceMoney.carder_id)
				console.log(this.transferAdvanceMoney)
				this.$api.TransferAdvanceMoney(this.transferAdvanceMoney).then(res=>{
					if(res.status == 1){
						this.$message({
							message: res.msg,
							type: 'success'
						});
						//重新查数据
						this.getData(this.todayParams,true)
						//重置弹窗数据
						this.dialogVisible = false ;
						this.close();
					}else{
						this.$message({
							message: res.msg,
							type: 'error'
						});
					}
					this.loading = false;
				}).catch(err=>{
					this.loading = false;
				})
				
			},
			close(){
				this.dialogVisible = false ;
				this.loading = false;
				this.transferAdvanceMoney={
					carder_id:'',
					amount:'',
					name:'',
					note:'',
					code:'',
				}
			}

		},
		watch: {
			
		},
	}
</script>

<style lang="scss" scoped="scoped">
	@import '@/style/variables.scss';

	.account-info{
		background: #e9ffeb;
    	padding: 20px;
		margin-left:40px
	}
	.cardBox {

		padding: 0 60px;
		.text {
			// font-size: 14px;
		}

		.clearfix:before,
		.clearfix:after {
			display: table;
			content: "";
			height: 1px;
			width: 100%;
		}

		.clearfix:after {
			clear: both
		}
		
		.box-card {
			width: 320px;
			box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.5);
    		margin-right: 60px;
			margin-bottom: 10px;
			&:nth-child(4n) {
				margin-right: 0;
			}
			.cardHeader {
				padding: 15px 20px;
				color: $baseColor;
				font-size: 22px
				
			}

			.cardCon {
				padding: 0 20px;
				background: rgba(0, 0, 0, 0.2);
				color: $baseColor;
				height: 130px;
				box-sizing: border-box;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				align-items: flex;
				font-size: 20px;

			}
		}
		&.accountCard{
			.box-card {
				.cardCon {
					height: 80px;

				}
			}
		}
	}

	.echartsBox {
		// height: ;
	}

	.echart {
		width: 45%;
		height: 400px;
	}

	// #main{
	// 	width: 500px;
	// 	height: 400px;
	// }
</style>
