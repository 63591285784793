<template>
	<div>
		<div class="content">
			<!-- 个人信息 -->
			<div class = "infoBox">
				<el-descriptions class="margin-top"  :column="3" size="medium" colon="true"	>
					
					<el-descriptions-item label="姓名"> {{dataObj.name}}</el-descriptions-item>
					<el-descriptions-item label="账号"> {{dataObj.username}}</el-descriptions-item>
					<el-descriptions-item label="登录时间"> {{dataObj.login_time}}</el-descriptions-item>
					<el-descriptions-item label="余额"> <el-tag size="small">{{dataObj.quota}}</el-tag></el-descriptions-item>
					<el-descriptions-item label="押金">
					<el-tag size="small"> {{dataObj.advance_money}}</el-tag>
					</el-descriptions-item>
				</el-descriptions>
			</div>



			<!-- <div class="mb20 pageTitle"><b>个人信息</b></div>
			<div class = "infoBox">
				
				
				<el-form class="form" label-width="65px" v-loading="loading">
					<el-form-item label="姓名:">
						<span></span>
					</el-form-item>
					<el-form-item label="账号:">
						<span></span>
					</el-form-item>
					<el-form-item label="余额:">
						<span></span>
					</el-form-item>
					<el-form-item label=":">
						<span></span>
					</el-form-item>
					
				</el-form>
				
			</div> -->
			
			<el-divider></el-divider>
			<div>
				<!-- <div class="mb20 pageTitle"><b>费率列表</b></div> -->
				<!-- <el-table :data="dataObj.feeList" size="mini"> -->
				<el-table  fit :max-height="height" :data="dataObj.feeList"  stripe v-loading="loading" :show-overflow-tooltip="true">
					<el-table-column type="index"  label="序号" width="50"></el-table-column>
					<el-table-column prop="pay_type" label="通道编号"></el-table-column>
					<el-table-column prop="name" label="通道名称"></el-table-column>
					<el-table-column prop="fee" label="费率"></el-table-column>
				</el-table>
			</div>
		</div>
	</div>
</template>

<script>
	import local from '@/util/local'
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isMobile,
		isIncludeHanzi
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				loading: false,
				height: 0,
				dataObj:{}
			};
		},
		created() {
			this.info();
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				// let pageTitle = document.getElementsByClassName('pageTitle')[0].offsetHeight;
				// let pageTitle1 = document.getElementsByClassName('pageTitle')[1].offsetHeight;
				let infoBox = document.getElementsByClassName('infoBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - infoBox - 20 
			})
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					let val = Math.floor(((value * 1) * 100).toPrecision(12))
					return ((val / 100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			info() {
				this.$api.info({}).then(res => {
					if (res.status == 1) {
						this.dataObj = res.data
					}
				})
			},
			onSubmit(){
				if (isEmpty(this.dataObj.telephone, "请输入电话号码")) {
					return
				}
				if (!isMobile(this.dataObj.telephone, "输入的电话号码不合法")) {
					return
				}
				let data = {
					phone: this.dataObj.telephone,
					skype: this.dataObj.skype
				}
				this.loading = true
				this.$api.setPhone(data).then(res=>{
					this.loading = false
					if(res.status == 1){
						this.info()
						this.$message({
							type: 'success',
							message: res.msg
						})
					}
				}).catch(error=>{
					this.loading = false
				})
			}
		},
		watch: {},
	};
</script>

<style lang="scss">
	@import '@/style/variables.scss';

	.content {
		.margin-top{
			font-size:16px;
		}
		.pageTitle {
			background-color: $navSubColor;
			padding: 10px;
			color: #fff;
		}

		.tips {
			width: 460px;
		}

		.form {
			width: 460px;
		}
	}
</style>
