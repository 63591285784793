<template>
	<div>
		<div class="queryBox">
			<div class="mb20">
				<el-button size="mini" plain class="mr20" @click="setDate(-1)" :disabled="disLoading">前一天</el-button>
				<el-date-picker size="mini" v-model="date" type="daterange" range-separator="至" start-placeholder="开始日期"
					end-placeholder="结束日期" class="mr20"></el-date-picker>
				<el-button size="mini" plain class="mr20" @click="setDate(1)" :disabled="disLoading">后一天</el-button>
				<el-select v-model="state" placeholder="请选择状态" size="mini" class="mr20"clearable>
					<el-option v-for="(item,index) in stateArr" :key="item.key" :label="item.val" :value="item.key">
					</el-option>
				</el-select>
				<el-select v-model="manual_state" size="mini" class="mr20" clearable placeholder="请选择类型">
					<el-option v-for="(item,index) in manualState" :key="item.key" :label="item.val" :value="item.key">
					</el-option>
				</el-select>
				<el-button size="mini" type="primary" :icon="disLoading?'el-icon-loading':'el-icon-search'" @click="getData" :disabled="disLoading">查询</el-button>
				<el-button v-if="false" size="mini" type="primary" :icon="disLoading?'el-icon-loading':'el-icon-thumb'"  @click="showDrawer" :disabled="disLoading">抢单</el-button>
				
			</div>
		</div>
		<template>
			<el-table size="mini" :max-height="height" :data="tableData" stripe v-loading="loading"
				:show-overflow-tooltip="true">
				<el-table-column type="index" label="序号" width="80px" ></el-table-column>
				<el-table-column prop="water_no" label="订单号" >
					<template slot-scope="scope">						
						<div class="flexY flexStart">
							<div class="flexX flexJustifyStart">
								<div class="whiteEllipsisHover" >商户:{{scope.row.order_id}}</div>
								<span> <i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(scope.row.order_id)"></i></span>
							</div>
							<div class="flexX flexJustifyStart">
								<div class="whiteEllipsisHover" >系统:{{scope.row.water_no}}</div>
								<span> <i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(scope.row.water_no)"></i></span>
							</div>
						</div>
					</template>
				</el-table-column>
				
				<el-table-column prop="" label="代付金额" >
					<template slot-scope="scope">
						{{scope.row.gold |  tofixed}}
					</template>
				</el-table-column>
				<el-table-column prop="pay_true_name" label="出款信息" width="200px">
					<template slot-scope="scope">		
						<el-popover
						placement="top-start"
						title=""
						width="200"
						trigger="hover"
						content="双击复制">
						<div slot="reference" class="flexY flexStart" @dblclick="handleCopydbl(scope.row.pay_true_name,scope.row.pay_card,scope.row.pay_bank)">
							<div class="flexX flexJustifyStart">
								<div class="whiteEllipsisHover" >姓名:{{scope.row.pay_true_name}}</div>
							</div>
							<div class="flexX flexJustifyStart">
								<div class="" >卡号:{{scope.row.pay_card}}</div>
								<!-- <span> <i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(scope.row.pay_card)"></i></span> -->
							</div>
							<div class="flexX flexJustifyStart">
								<div class="whiteEllipsisHover" >银行:{{scope.row.pay_bank}}</div>
							</div>
						</div>
 						</el-popover>

						
					</template>

				</el-table-column>
				<!-- <el-table-column prop="" label="代付二维码" >
					<template slot-scope="scope">
						<el-image v-if="scope.row.payment_url" fit="contain"
							style="width: 50px; height: 50px"
							:src="`http://${scope.row.payment_url}`" 
							:preview-src-list="[`http://${scope.row.payment_url}`]">
						  </el-image>
					</template>
				</el-table-column> -->
					
				<!-- </el-table-column> -->
				<!-- <el-table-column prop="pay_card" label="代付卡号" width="200px" ></el-table-column> -->
				<!-- <el-table-column prop="pay_bank" label="代付银行" ></el-table-column> -->

				<!-- <el-table-column prop="bank" label="银行" width="170px" ></el-table-column> -->
				<!-- <el-table-column prop="" label="代付金额" >
					<template slot-scope="scope">
						{{scope.row.true_gold |  tofixed}}
					</template>
				</el-table-column> -->
				<!-- <el-table-column prop="" label="类型" >
					<template slot-scope="scope">
						<div class="" :class="scope.row.manual_state==0? 'cwarning':'csuccess'">
							{{scope.row.manual_state==0?'自动':'手动'}}
						</div>
					</template>
				</el-table-column> -->
				<el-table-column prop="" label="状态" >
					<template slot-scope="scope">
						<div class=""
							:class="scope.row.state==2? 'cwarning':scope.row.state==1? 'csuccess':''">
							{{scope.row.state_name}}
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="" label="凭证" >
					<template slot-scope="scope">
						<!-- <el-image v-if="scope.row.receipt_url" fit="contain"
						    style="width: 50px; height: 50px"
						    :src="`http://${scope.row.receipt_url}`" 
						    :preview-src-list="[`http://${scope.row.receipt_url}`]">
						  </el-image> -->
						  <div class="mt20 mr20">
							<el-badge :hidden ="scope.row.receipt_url.length < 2" v-if="scope.row.receipt_url" :value="scope.row.receipt_url.length" class="item">
								<el-image fit="contain"
									style="width: 50px; height: 50px"
									:src="scope.row.receipt_url[0]" 
									:preview-src-list="scope.row.receipt_url">
								</el-image>
								
							</el-badge>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="add_time" label="提交时间" width="160px" >
					<template slot-scope="scope">						
						<div class="flexY flexJustifyStart">
							提交:{{scope.row.add_time}}<br>
							完成:{{scope.row.action_time}}<br>
							<span class="cdanger">耗时:{{(scope.row.action_time && scope.row.add_time)? getTimeDifference(scope.row.add_time,scope.row.action_time):''}}</span>
						</div>
					</template>
				
				</el-table-column>
				
				<el-table-column label="操作" >
					<template slot-scope="scope">
						<div v-if="scope.row.state == 2">
							<div v-if="scope.row.is_request == 0" @click="orderOutRequest(scope.row)" class="pointer cprimary">领取订单</div>
							<div v-if="scope.row.is_request == 1" class="pointer cprimary" @click="CompleteOrder(scope.row)">完成订单</div>
							<div v-if="scope.row.is_request == 1" class="pointer cprimary" @click="backOrder(scope.row)">退回订单</div>
							<div v-if="scope.row.is_request == 1" class="pointer cprimary" @click="closeOrder(scope.row)">关闭订单</div>
						</div>
						
					</template>
				</el-table-column>
			</el-table>
		</template>
		<div class="flexX  mt20 pageBox">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page="page" :page-sizes="pageSizesArr"
				:page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
	
		<el-dialog center class="dialog" top="150px" title="" :visible.sync="dialogVisible" @close="close" width="50%" :close-on-click-modal="closeModal">
			<div class="formBox" v-loading="dialogloading">
				<div class="textCenter">
					<div v-if="orderState == 1">
						<!-- <div class="flexX flexcenter mb20">
							<span>卡真实余额：</span>
							<el-input placeholder="请输入卡真实余额" style="width: 200px;" v-model="card_true_quota"></el-input>
						</div> -->
						<div class="flexX flexcenter">
							<span>回执图片(可不上传凭证)：</span>	
							<div class="flexX flexJustifyStart flexWrap">
								<!-- <div class="flexX flexJustifyStart "> -->
									<div v-for='(item,index) in imageUrlArr' class="image-box" >
										<img :src="item" class="avatar mb10 mr10"/>	
										<!-- <span></span> -->
										<div class="delImg"><i class="el-icon-delete delIcon" @click="deleteImg(index)"></i></div>
										
									</div>
																
								</el-image>
									<el-upload
									class="avatar-uploader"
									:action="`${this.$baseURL}carder/order/uploadImage`"
									:data="uploadData"
									name="image"
									:show-file-list="false"
									:on-success="handleAvatarSuccess">
										<!-- <img v-if="imageUrl" :src="imageUrl" class="avatar"> -->

										<i  class="el-icon-plus avatar-uploader-icon"></i>
									</el-upload>

								<!-- </div> -->
							</div>						
						</div>
					</div>
					<div v-if="orderState == 2">
						<div class="flexX flexcenter mb20 mt40" style="color: red; font-size: 1.125rem; font-weight: 600;">
							收款方原因(收款账号错误等)无法出款，使用功能【关闭订单】
						</div>
						<div class="flexX flexcenter mb20 mt40">
							<span>关闭订单原因：</span>
							<el-select @change="changeReason" v-model="closeReason" size="mini" class="mr20" clearable placeholder="请选择关闭订单原因">
								<el-option v-for="item in closeReasonObjClose" :key="item" :label="item" :value="item">
								</el-option>
							</el-select>
						</div>
						<div class="flexX flexcenter mb20" v-if="closeReason == '其他'">
							<span>其他原因：</span>
							<el-input placeholder="请输入其他原因" style="width: 200px;" v-model="closeReasonText"></el-input>
						</div>
					</div>
					<div v-if="orderState == 3">
						<div class="flexX flexcenter mb20 mt40" style="color: red; font-size: 1.125rem; font-weight: 600;">
							自己的原因（如账号余额不足,封控冻结等）无法出款，使用功能【退回订单】
						</div>
						<div class="flexX flexcenter mb20 mt40">
							<span>退回订单原因：</span>
							<el-select @change="changeReason" v-model="closeReason" size="mini" class="mr20" clearable placeholder="请选择退回订单原因">
								<el-option v-for="item in closeReasonObj" :key="item" :label="item" :value="item">
								</el-option>
							</el-select>
						</div>
						<div class="flexX flexcenter mb20" v-if="closeReason == '其他'">
							<span>其他原因：</span>
							<el-input placeholder="请输入其他原因" style="width: 200px;" v-model="closeReasonText"></el-input>
						</div>
					</div>
				</div>
				<div class="textCenter">
					<el-button type="primary" @click="sure()">确定</el-button>
					<el-button  @click="close">关闭</el-button>
				</div>
			</div>
		</el-dialog>
		
		<el-drawer
		size="60%"
		  title="抢单"
		  :visible.sync="drawer"
		  :direction="direction"
		  :before-close="handleClose">
		  <div class="flexX flexcenter mb20">
			  <div class="mr10"  style="width: auto;">最小金额：</div><el-input style="width: 150px;" class="textCenter mr50"  type="number" v-model="check_min_gold" placeholder="请输入最小金额" size="100px"/>
			  
			  <div class="mr10"  style="width: auto;">最大金额：</div><el-input style="width: 150px;" class="textCenter"  type="number" v-model="check_max_gold" placeholder="请输入最大金额" size="100px"/>
		  </div>
			<el-table :data="qdData">
		        <el-table-column property="add_time" label="提交时间" width="150"></el-table-column>
		        <el-table-column property="gold" label="金额"></el-table-column>
		        <el-table-column property="bank" label="类型" width="200"></el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<div v-if="scope.row.id">
							<div class="pointer cprimary" @click="qdSure(scope.row)">抢单</div>
						</div>
						
					</template>
				</el-table-column>
		      </el-table>
		</el-drawer>
	</div>
</template>

<script>
	import local from '@/util/local'
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isMobile,
		isIncludeHanzi
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				loading: false,
				disLoading: false,
				height: 0,
				tableData: [],
				pageSizesArr: [20, 50, 100, 200],
				pageSize: 20,
				total: 0,
				page: 1,
				date: null,
				stateArr: [{
						key: 3,
						val: "全部"
					},
					{
						key: 1,
						val: "已完成"
					},
					{
						key: 2,
						val: "未处理"
					},
				],
				state: 3,
				manualState: [{
						key: '',
						val: "全部"
					}, {
						key: 0,
						val: "自动"
					},
					{
						key: 1,
						val: "手动"
					},
				],
				manual_state: "",
				item:{},
				dialogVisible:false,
				dialogloading:false,
				closeModal:false,//是否点击弹框 关闭
				orderState:'1',//1 完成订单   2 关闭订单  3退回订单
				card_true_quota:'',//卡真实余额
				imageUrl:'',
				uploadData:{},
				imgPath:'',
				closeReasonObj:{},//退回订单原因,
				closeReasonObjClose:{},//关闭原因
				closeReason:'',
				closeReasonText:'',
				// 抢单
				drawer: false,
				direction: 'rtl',
				qdData: {},
				qdt: null,
				check_min_gold: 0,
				check_max_gold: 50000,
				//多个凭证 图片展示及上传
				exampleDataImgArr:['https://pic35.photophoto.cn/20150511/0034034892281415_b.jpg','https://tse1-mm.cn.bing.net/th/id/OIP-C.qmIh5R-d_DmDKOYgzN09agHaJQ?rs=1&pid=ImgDetMain'],
				imgPathArr:[],
				imageUrlArr:[],
			};
		},
		created() {
			this.date = [this.$util.timestampToTime(new Date().getTime(), true), this.$util.timestampToTime(new Date()
				.getTime(),
				true)];
				if (this.$route.query.state) {
					this.state = Number(this.$route.query.state);
				}
			this.orderOutList()
			this.orderOutState()
			this.orderOutStateClose()
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
			})
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					let val = Math.floor(((value * 1) * 100).toPrecision(12))
					return ((val / 100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			showDrawer(){
				this.drawer = true
				this.qdData = []
				this.orderQdList()
				
				this.qdt = setInterval(this.orderQdList, 5000);
			},
			handleClose(done) {
				this.closeDrawer()
		    },
			closeDrawer(){
				this.qdData = []
				this.drawer = false
				clearInterval(this.qdt);
				this.qdt = null;
			},
			// 可抢单的订单列表.
			orderQdList() {
				let data = {
					min_gold: String(this.check_min_gold),
					max_gold: String(this.check_max_gold),
				}
				this.disLoading = true
				this.$api.orderOutSnatchList(data).then(res => {
					this.loading = false;
					this.disLoading = false;
					if (res.status === 1) {
						this.qdData = res.data.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading = false
					this.disLoading = false
				})
			}, 
			qdSure(item){
				let data ={
					id:item.id,
				}
				this.$confirm('确认抢单吗?', '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.loading = true
					this.$api.orderOutSnatch(data).then(res=>{
						this.loading = false
						if(res.status == 1){
							this.closeDrawer()
							this.orderOutList()
							this.$message({
								type: 'success',
								message: res.msg
							})
						}else{
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
						
					}).catch(e=>{
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消抢单'
					});
				});
			},
			setDate(day) {
				let data = this.date ? this.date[0] : this.$util.timestampToTime(new Date().getTime(), true)
				let newDate = this.$util.getLastDate(data, day);
				this.date = [newDate, newDate]
				this.getData()
			},
			getTimeDifference(startTime, endTime) {
				return this.$util.intervalTime(startTime, endTime)
			},
			//搜索按钮
			getData() {
				this.page = 1;
				this.orderOutList();
			},

			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val
				this.page = 1
				this.orderOutList()
			},
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.page = val
				this.orderOutList()
			},
			orderOutList() {
				let data = {
					// date_time: this.showDate ? this.date : '',
					state: this.state,
					manual_state: this.manual_state,

					start_time: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[0])), true) : '',
					end_time: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[1])), true) : '',
					page: this.page,
					size: this.pageSize,
				}
				
				this.disLoading = true
				this.$api.orderOutList(data).then(res => {
					this.loading = false;
					this.disLoading = false;
					if (res.status === 1) {
						for(let i = 0; i < res.data.data.length; i++){							
							if(res.data.data[i].receipt_url != ''){
								res.data.data[i].receipt_url = res.data.data[i].receipt_url.split(',')	
								// for(let j = 0; j < res.data.data[i].receipt_url.length; j++){
								// 	res.data.data[i].receipt_url[j] = 'http://'+res.data.data[i].receipt_url[j]
								// }
							}
							
						}
						this.tableData = res.data.data;
						this.total = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading = false
					this.disLoading = false
				})
			},

			//获取关闭订单原因			
			orderOutStateClose(){
				this.$api.orderOutStateClose({}).then(res => {
					if (res.status === 1) {
						this.closeReasonObjClose = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
				})
			},
			//	获取手动代付订单退回原因
			orderOutState(){
				this.$api.orderOutState({}).then(res => {
					if (res.status === 1) {
						this.closeReasonObj = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
				})
			},
			//领取订单
			orderOutRequest(item) {
				let data = {
					id: item.id
				}
				this.$api.orderOutRequest(data).then(res => {
					if (res.status == 1) {
						item.is_request = 1;
						this.$message({
							type: 'success',
							message: res.msg
						})
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			//完成订单
			CompleteOrder(item){
				this.item = item
				this.uploadData ={
					username: local.get('bpcusername'),
					tid: local.get('bpctid'),
					// card:this.item.own_card,
					card_order_id:this.item.card_order_id,
					login_type:2,
				} 
				this.orderState='1'
				this.dialogVisible = true
			},
			//关闭订单
			closeOrder(item){
				this.item = item
				this.orderState='2'
				this.dialogVisible = true
			},
			//退回订单
			backOrder(item){
				this.item = item
				this.orderState='3'
				this.dialogVisible = true
			},
			orderOutComplete(imgPath){
				// if(isEmpty(this.card_true_quota,"请输入卡真实余额")){
				// 	return
				// }
				// if(!isMoney(this.card_true_quota,"卡真实余额只能是数字且最多保留两位小数")){
				// 	return
				// }
				// let receipt_url = this.imgPathArr.join(',')
				let data ={
					id:this.item.id,
					// card_true_quota:this.card_true_quota,
					// receipt_url:this.imgPath,
					receipt_url:this.imgPathArr.join(','),
				}
				// console.log('data',data)
				// return
				this.loading = true
				this.$api.orderOutComplete(data).then(res=>{
					this.loading = false
					if(res.status == 1){
						this.orderOutList();
						this.close()
					}else{
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
					
				}).catch(e=>{
					this.loading = false
				})
			},
			orderOutClose(){
				if(this.closeReason==""){
					this.$message({
						type: 'error',
						message: "请选择关闭订单原因"
					})
					return
				}
				// if(this.closeReason == '余额不足'){
				// 	if(isEmpty(this.card_true_quota,"请输入卡真实余额")){
				// 		return
				// 	}
				// 	if(!isMoney(this.card_true_quota,"卡真实余额只能是数字且最多保留两位小数")){
				// 		return
				// 	}
				// }
				if(this.closeReason == '其他' && this.closeReasonText  =="" ){
					this.$message({
						type: 'error',
						message: "请输入关闭订单原因"
					})
				}
				let data = {
					id:this.item.id,
					note:this.closeReason == '其他'?this.closeReasonText:this.closeReason
				}
				// if(this.closeReason == '余额不足'){
				// 	data.card_true_quota = this.card_true_quota
				// }
				this.loading = true
				this.$api.orderOutClose(data).then(res=>{
					this.loading = false
					if(res.status == 1){
						this.orderOutList();
						this.close()
					}else{
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
					
				}).catch(e=>{
					this.loading = false
				})
			},
			orderOutSnatchReject(){
				if(this.closeReason==""){
					this.$message({
						type: 'error',
						message: "请选择退回订单原因"
					})
					return
				}
				if(this.closeReason == '其他' && this.closeReasonText  =="" ){
					this.$message({
						type: 'error',
						message: "请输入退回订单原因"
					})
				}
				let data = {
					id:this.item.id,
					note:this.closeReason == '其他'?this.closeReasonText:this.closeReason
				}
				// if(this.closeReason == '余额不足'){
				// 	data.card_true_quota = this.card_true_quota
				// }
				this.loading = true
				this.$api.orderOutSnatchReject(data).then(res=>{
					this.loading = false
					if(res.status == 1){
						this.orderOutList();
						this.close()
					}else{
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
					
				}).catch(e=>{
					this.loading = false
				})
			},
			close(){
				this.item = {}
				this.uploadData ={} 
				this.imageUrl = ""
				this.dialogVisible = false
				this.dialogloading = false
				this.card_true_quota = ""
				this.closeReasonText = ""
				this.closeReason=""
				this.imgPath=""
				this.imgPathArr = []
				this.imageUrlArr = []
			},
			handleAvatarSuccess(res, file) {
				// console.log(res,file)
				this.imageUrl = URL.createObjectURL(file.raw);
				this.imgPath = res.data;
				this.imgPathArr.push(this.imgPath)
				this.imageUrlArr.push(this.imageUrl)
				// console.log(res.data)
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 2;
		
				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isJPG && isLt2M;
			},
			deleteImg(index){
				this.imgPathArr.splice(index,1)
				this.imageUrlArr.splice(index,1)
				// console.log(this.imgPathArr,'==',this.imageUrlArr)
			},
			sure(){
				if(this.orderState == "1"){					
					this.orderOutComplete()
				}
				if(this.orderState == "2"){					
					this.orderOutClose()
				}
				if(this.orderState == "3"){					
					this.orderOutSnatchReject()
				}
			},
			changeReason(e){
				this.card_true_quota = ""
				this.closeReasonText = ""
			},
			handleCopy(textCopy){
				
				const contentToCopy = textCopy
				// 创建一个临时的 textAREA 元素
				const textarea = document.createElement('textarea')
				textarea.value = contentToCopy
				document.body.appendChild(textarea)
				textarea.select()
				document.execCommand('copy')
				document.body.removeChild(textarea)
				this.$message({
					type: 'success',
					message: '已复制到剪切板'
				})
			},
			handleCopydbl(t1,t2,t3){
				const contentToCopy = t1+'\n'+t2+'\n'+t3
				// 创建一个临时的 textAREA 元素
				const textarea = document.createElement('textarea')
				textarea.value = contentToCopy
				document.body.appendChild(textarea)
				textarea.select()
				document.execCommand('copy')
				document.body.removeChild(textarea)
				this.$message({
					type: 'success',
					message: '已复制到剪切板'
				})
			}
			
			
		},
		watch: {
			total(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
				})
			}
		},
	};
</script>

<style scoped="scoped" lang="scss">
	.formBoxbd {
		width: 80%;
		margin: 0 auto;
	}
	.image-box{
		position: relative;
		.delImg{
				position: absolute;
				width: calc(100% - 10px);
				height: calc(100% - 10px);
				top:0;
				left:0;
				display: none;
				background: rgba(0,0,0,.5);
				color:#fff;				
				justify-content: center;
				align-items: center;
				font-size: 26px;
				.delIcon{
					cursor: pointer;
				}
			}
		&:hover{			
			.delImg{
				display:flex;
			}
		}
	}
</style>
<style type="text/css">
	.avatar-uploader .el-upload {
	    border: 1px dashed #d9d9d9;
	    border-radius: 6px;
	    cursor: pointer;
	    position: relative;
	    overflow: hidden;
	  }
	  .avatar-uploader .el-upload:hover {
	    border-color: #409EFF;
	  }
	  .avatar-uploader-icon {
	    font-size: 28px;
	    color: #8c939d;
	    width: 178px;
	    height: 178px;
	    line-height: 178px;
	    text-align: center;
	  }
	  .avatar {
	    width: 178px;
	    height: 178px;
	    display: block;
	  }
</style>
