<template>
	<div class="login-container">
		<div class="backBox">
			<div>
				<div class="loginBox">
					<div class="loginTitle" v-if="!googleCode">登 录</div>
					<div class="loginTitle" v-if="googleCode">谷歌验证</div>
					<span><el-input autocomplete="new-password" class="loginInput" prefix-icon="el-icon-user" placeholder="请输入用户名" v-model="username" clearable></el-input></span>
					<span><el-input autocomplete="new-password" class="mt40 loginInput" prefix-icon="el-icon-lock" placeholder="请输入密码" v-model="password" show-password></el-input></span>
					<span><el-input class="mt40 loginInput" prefix-icon="el-icon-key" placeholder="请输入谷歌验证码" v-model="code" clearable v-if="!googleCode"></el-input></span>
					<span><el-button @click="login()" class="mt40" type="warning" style="width: 100%;" v-if="!googleCode" :disabled="loading">登 录</el-button></span>
					<span><el-button class="mt40" type="warning" style="width: 100%;" @click="toGoogleCode()" v-if="!googleCode">谷歌验证</el-button></span>
					<span><el-button class="mt40" type="warning" style="width: 100%;" @click="validateGcode()"v-if="googleCode">下一步</el-button></span>
					
				</div>
			</div>
		</div>
		<el-dialog class="dialog" top="200px" title="验证码" :visible.sync="dialogVisible" width="400px">
			<div class="qrcode">
				<img :src="qrcode" alt="">
			</div>
		</el-dialog>
	</div>
</template>

<script>
	// import QRCode from 'qrcodejs2'
	import {
		isEmpty,
		isPositiveNumber,
		isMoney
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	import local from '../../util/local.js'
	export default {
		name: 'Login',
		data() {
			return {
				username:'',
				password:'',
				code:'',
				dialogVisible: false,
				qrcode:'',
				googleCode:false,
				loading:false
			}
		},
		created() {
			
		},
		mounted(){
			let self = this
			document.onkeydown = function(e) {
			  let ev = document.all ? window.event : e
			  if (ev.keyCode === 13) {
				  if(!self.loading){
					  self.login()
				  }
				
			  }
			}
		},
		watch: {},
		methods: {
			login(){
				if(isEmpty(this.username,"请输入账号")){
					return
				}
				if(isEmpty(this.password,"请输入密码")){
					return
				}
				if(isEmpty(this.code,"请输入谷歌验证码")){
					return
				}
				let data={
					username:this.username,
					password:this.$md5(this.password) ,
					code:this.code,
					login_type:2
				}
				this.loading = true
				this.$api.login(data).then(res => {
					this.loading = false
					// console.log(res)
					if(res.status === 1){
						this.$local.set('bpcusername',res.data.username);
						this.$local.set('bpctid',res.data.tid);						
						this.$local.set('telephone',res.data.telephone)
						if(res.data.telephone){
							//电话号码登录(子账号登录)，只能查看操作order代付页面
							this.$router.push({path:'/orderRecharge'})
							this.$parent.enableList()
						}else{
							this.$router.push({path:'/allIndex'})
						}
						this.infoTotal()
						this.$api.payTypeAll({}).then(respay=>{
							if(respay.status == 1){
								let payList = respay.data 
								this.$local.set('payList',payList);
								this.$parent.enableList()	
							}
						}).catch(e=>{
							
						})
					}else{
						this.$message({
						  type: 'error',
						  message: res.msg
						}); 
					}
				}).catch(error=>{
					this.loading = false
				})
			},
			close(){
				
			},
			validateGcode(){
				if(isEmpty(this.username,"请输入账号")){
					return
				}
				if(isEmpty(this.password,"请输入密码")){
					return
				}
				let data={
					username:this.username,
					password:this.$md5(this.password),
					login_type:2,//1-APP 2-PC
				}
				
				this.$api.validateGcode(data).then(res=>{
					// console.log(res)
					//1 成功 未绑定 3已绑定   2未登录  其他失败
					if(res.status === 1){
						local.set('googleInfo',res.data)
						local.set('pw',this.$md5(this.password))
						this.$router.push({path:"/googleVerification"})
					}else if(res.status == 3){
						this.googleCode = false
						this.$message({
						  type: 'info',
						  message: res.msg
						});    
					}else{
						this.$message({
						  type: 'info',
						  message: res.msg
						});    
					}
				})
			},
			toGoogleCode(){
				this.googleCode=true;
			},	
			infoTotal(){
				this.$api.infoTotal({}).then(res=>{
					if(res.status == 1){
						let data = res.data
						this.$local.set('bpcUserinfo',res.data);
					}
				})
			},
		}
	}
</script>
<style>
	.loginInput .el-input__inner{
		background: transparent;
		color: #fff;
		border: none;
		border-bottom: solid 1px #ddd;
		border-radius: 0;
	}
</style>
<style lang="scss" scoped>
	.login-container {
		height: 100vh;
		// display: flex;
		// align-items: center;
		// justify-content: center;
		// background: url(../../assets/image/bg.png) no-repeat;
		background: url(../../assets/image/bg1.png) no-repeat;
		background-size: cover;
		.backBox{
			height: 100vh;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: rgba(0,0,0,0.7);
		}
		.loginTitle{
			font-size: 20px;
			text-align: center;
			margin-bottom: 40px;
			color: #fff;
		}
		.loginBox{
			// background-color: rgba(255,255,255,0.5);
			padding: 60px 20px;
			width: 400px;
			// padding-bottom: 100px;
			box-shadow: 0 0 10px 2px rgba(0,0,0,0.1);			
		}
		
	}
	.qrcode{
	    display: inline-block;
		width: 100%;
		height: 100%;
	    img {
			width: 100%;
	        background-color: #fff; //设置白色背景色
	        box-sizing: border-box;
	    }
	}
	.dialog{
		// transform: translate(0,25%);
	}
</style>
