<template>
	
	<el-dialog  center class="dialog" top="100px" :visible.sync="params.dialogVisible" @close="close" width="35%" :close-on-click-modal="closeModal" :title="dialogTitle">
		<div class="formBox" v-loading="dialogloading">
			<div class="textCenter">
				<div style="font-size: 1rem;">
					<div class="flexX flexcenter mb20">
					    <span style="width: 130px;">机位:</span>
					    <el-select size="medium" class="mr20" filterable v-model="dataDetails.mobile_id" clearable placeholder="请选择机位">
					        <el-option v-for="(item, index) in mobileData" :key="item.id" :label="item.name" :value="item.id">
					        </el-option>
					    </el-select>
					</div>
					<div class="flexX flexcenter mb20">
					    <span style="width: 130px;">匹配金额:</span>
						<el-autocomplete
							class="inline-input"
							v-model="dataDetails.match_gold"
							:fetch-suggestions="querySearch"
							placeholder="请输入匹配金额"
							>
						</el-autocomplete>
					    <!-- <el-select size="medium" class="mr20" v-model="dataDetails.match_gold" clearable placeholder="请选择状态">
					        <el-option  style="font-size: 0.9375rem;" v-for="(item, index) in matchMoney" :key="item.key" :label="item.val" :value="item.key">
					        </el-option>
					    </el-select> -->
					</div>
					<div v-if="pay_type == 105 || pay_type == 107" class="flexX flexcenter mb20">
					    <span style="width: 130px;">账号：</span>
					    <el-input class="mr10" style="width: 180px; font-size: 1.25rem;" type="text" placeholder="请输入账号" v-model="dataDetails.account"></el-input>
						<el-button size="mini" type="primary" @click="generateAccount">生成账号</el-button>
					</div>
					<div v-else class="flexX flexcenter mb20">
					    <span style="width: 130px;">账号：</span>
					    <el-input class="mr10" style="width: 180px; font-size: 1.25rem;" type="text" placeholder="请输入账号" v-model="dataDetails.account"></el-input>
					    <span class="mr10" v-if="notUsedTime > 0">剩余时间: {{ notUsedTime }}秒</span>
					    <el-button size="mini" type="primary" :disabled="!canGetAccount" @click="getAccount">重新获取账号</el-button>
					</div>
					<div class="flexX flexcenter mb20" v-if="pay_type == 107">
					    <span style="width: 130px;">姓名：</span>
					    <el-input class="mr10" style="width: 180px; font-size: 1.25rem;" type="text" placeholder="请输入姓名" v-model="dataDetails.true_name"></el-input>
					   
					</div>
					<div class="flexX flexcenter mb20">
						<span style="width: 130px;">状态：</span>
						<el-switch size="medium" @change="changeAliState" v-model="aliState" active-text="启用"
							inactive-text="禁用"></el-switch>
					</div>
					<div class="flexX flexcenter mb20">
						<span style="width: 130px;">收款二维码：</span>
						<div>
							<el-button class="mr20" size="mini" type="success" @click="showBigImg('收款二维码',fileurl)" :disabled="!fileurl">查看大图 </el-button>
						</div>
						<el-upload action="" :show-file-list="false" :http-request="resolveQR">
							<img slot="trigger" v-if="fileurl" class="upload-img" :src="fileurl">
							<el-button v-else type="success">上传</el-button>
						</el-upload>
					</div>
					
					<div class="flexX flexcenter mb20">
						<span style="width: 130px;">收款链接:</span>
						<el-input size="medium" style="width: 400px;" type="text" placeholder="请输入收款链接" v-model="dataDetails.qrcode_url"></el-input>
					</div>
					
				</div>
	
				<el-button size="mini" type="primary" @click="sure()"   :icon="disLoading ? 'el-icon-loading' : 'el-icon-search'" :disabled="disLoading">确定</el-button>
				<el-button size="mini" @click="close" :icon="disLoading ? 'el-icon-loading' : 'el-icon-search'" :disabled="disLoading">关闭</el-button>
			</div>
		</div>
		<el-dialog :title="qrImgTitle" top="150px" width ="30%" id ='myDialog' :visible.sync="innerVisibleImg" append-to-body @opened="opened" center>
			<div class="image-dialog">
			<el-image  :src="showImg"></el-image></div>
		</el-dialog>
		<div @click="closePopup()" class="popup" :class="{ 'show': showPopup }">
			<div id="qr" class="qrcode" ref="qrCodeUrl"></div>
		</div>
	</el-dialog>
</template>

<script>
import {
	isEmpty,
	isPositiveNumber,
	isMoney,
	isMobile
} from '@/util/validate'
import {
	Message
} from 'element-ui'
import local from '@/util/local'
import QRCode2 from 'qrcodejs2'
import QrCode from '../../node_modules/qrcode-decoder/dist/index'
	export default {
	props: {
		  params: {
			type: Object,
			default: () => ({}) // 确保默认值为一个空对象
		  },
		  matchMoney:{
			type: Array,
			default:()=>( [
				{
					key: '50',
					value: "50"
				},
				{
					key: '100',
					value: "100"
				},
				{
					key: '200',
					value: "200"
				}
			])
		}
	},
	data() {
		
	    return {
		  disLoading: false,
	      dialogTitle: "",
	      dialogType: '',
	      dialogVisible: true,
	      dialogloading: false,
	      closeModal: false, //是否点击弹框 关闭，
	      dataDetails: {
	      	true_name: "",
	      	account_branch: "",
	      	card: "",
	      	bank: "",
	      	telephone: "",
	      	state: 0,
	      	province: "",
	      	city: "",
	      	login_password: "",
	      	pay_password: "",
	      	account: '',
	      	bank_name: '',
	      	nickname: '',
	      	data: {},
	      	callback_id: '',
	      	year_total_money: '',
	      	eve_used_money: '',
	      	eve_used_num: '',
	      	qrcode_url: '',
	      	frcode_link:'',
	      	qrcode_link:'',
	      	gold: '',
	      	total_num: '',
	      	match_gold: '100',
	      	hd_order_id: '',
	      	mobile_id: '',
	      },
			innerVisibleImg:false,
			aliState: true,
			fileurl: null,
			fileurlFriends:null,
			showImg:null,
			payList:{},
			qrImgTitle:'',
			radio: '1',
			showPopup: false,
			restaurants:[],
		
		notUsedTime: 0, // 初始倒计时时间
		timer: null, // 倒计时定时器
		canGetAccount: true, // 是否允许重新获取账号
		  // 机位
		mobileData: [],
		pay_type: '',
	    };
	},
	mounted() {
      this.restaurants = this.matchMoney;
    },
	created() {
		this.dialogVisible = true
		this.dataDetails.mobile_id = this.params.mobile_id
		this.pay_type = this.params.pay_type
		if(this.pay_type == 103 || this.pay_type == 104){
			this.dataDetails.match_gold = '100'
		}
		if(this.pay_type == 105){
			this.dataDetails.match_gold = '200'
		}else{
			this.dataDetails.match_gold = ''
		}
		this.getMobiles()
		console.log(this.params)
	},
	methods: {
		// 生成唯一账号
		generateAccount() {
		  const timestampPart = Date.now().toString().slice(-8); // 获取时间戳的最后5位
		  const randomPart = Math.floor(1000 + Math.random() * 9000).toString(); // 生成7位随机数
		  let accountId = timestampPart + randomPart;

		  if (accountId.length > 10) {
			accountId = accountId.slice(0, 10); // 如果长度超过12位，截取前12位
		  }
		  let str = ''
		  if(this.pay_type == 105){
			str = 'LQ'
		  }
		  if(this.pay_type == 107){
			str = 'YE'
		  }
		  this.dataDetails.account = str+accountId;
		},
		sure() {
			if(this.params.type == 'new'){
				let data = {
					true_name: this.dataDetails.true_name,
					account: this.dataDetails.account,
					nickname: this.dataDetails.nickname,
					telephone: this.dataDetails.telephone,
					qrcode_url: this.dataDetails.qrcode_url,
					frcode_url: this.dataDetails.frcode_url,
					ali_userid: this.dataDetails.ali_userid,
					state:  this.dataDetails.state,
					pay_type: this.pay_type, // 支付宝
					callback_id: this.dataDetails.callback_id,
					frcode_link: this.dataDetails.frcode_link,
					qrcode_link: this.dataDetails.qrcode_link,
					eve_used_num: this.dataDetails.eve_used_num,
					eve_used_money: this.dataDetails.eve_used_money,
					gold: this.dataDetails.gold,
					total_num: this.dataDetails.total_num,
					match_gold: this.dataDetails.match_gold,
					hd_order_id: this.dataDetails.hd_order_id,
					mobile_id: this.dataDetails.mobile_id
				}
				if (isEmpty(this.dataDetails.account, "请输入账号")) {
					return
				}
				// if (isEmpty(this.dataDetails.nickname, "请输入昵称")) {
				// 	return
				// }
				// if (isEmpty(this.dataDetails.true_name, "请输入真实姓名")) {
				// 	return
				// }
				// if (isEmpty(this.dataDetails.gold, "请输入收款金额")) {
				// 	return
				// }
				// if (isEmpty(this.dataDetails.total_num, "请输可收笔数")) {
				// 	return
				// }
				if (isEmpty(this.dataDetails.match_gold, "请输入匹配金额")) {
					return
				}

				this.disLoading = true
				this.$api.addAlipay(data).then(res => {
					
					this.disLoading = false
					if (res.status == 1) {
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.close()
						this.getData()

					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			}else{
				let data = {
					id: this.dataDetails.id,
					true_name: this.dataDetails.true_name,
					account: this.dataDetails.account,
					nickname: this.dataDetails.nickname,
					telephone: this.dataDetails.telephone,
					qrcode_url: this.dataDetails.qrcode_url,
					frcode_url: this.dataDetails.frcode_url,
					ali_userid: this.dataDetails.ali_userid,
					state:  this.dataDetails.state,
					pay_type: this.pay_type, // 支付宝
					callback_id: this.dataDetails.callback_id,
					frcode_link: this.dataDetails.frcode_link,
					qrcode_link: this.dataDetails.qrcode_link,
					eve_used_num: this.dataDetails.eve_used_num,
					eve_used_money: this.dataDetails.eve_used_money,
					gold: this.dataDetails.gold,
					total_num: this.dataDetails.total_num,
					match_gold: this.dataDetails.match_gold,
					hd_order_id: this.dataDetails.hd_order_id,
					mobile_id: this.dataDetails.mobile_id,
				}
				
				if (isEmpty(this.dataDetails.account, "请输入账号")) {
					return
				}
				// if (isEmpty(this.dataDetails.nickname, "请输入昵称")) {
				// 	return
				// }
				// if (isEmpty(this.dataDetails.true_name, "请输入真实姓名")) {
				// 	return
				// }
				// if (isEmpty(this.dataDetails.gold, "请输入收款金额")) {
				// 	return
				// }
				// if (isEmpty(this.dataDetails.total_num, "请输入可收笔数")) {
				// 	return
				// }
				if (isEmpty(this.dataDetails.match_gold, "请输入匹配金额")) {
					return
				}
				// if (isEmpty(this.dataDetails.year_total_money, "请输入年累计限额")) {
				// 	return
				// }
				// if (isEmpty(this.dataDetails.eve_used_money, "请输入日收上限")) {
				// 	return
				// }
				// if (isEmpty(this.dataDetails.eve_used_num, "请输入日收笔数")) {
				// 	return
				// }
				// if (isEmpty(this.listIn, "请选择支付类型")) {
				// 	return
				// }
				this.disLoading = true
				this.$api.editAlipay(data).then(res => {
					this.disLoading = false
					if (res.status == 1) {
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.close()
						this.getData()
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			}
			// let data = {
			// 	qr_id: this.item.id,
			// 	note: this.item.note,
			// }
			// this.$api.editStoreQr(data).then(res => {
			// 	if (res.status == 1) {
			// 		this.close()
			// 		this.alipayList()
			// 		this.$message({
			// 			type: 'success',
			// 			message: res.msg
			// 		})
			// 	} else {
			// 		this.$message({
			// 			type: 'error',
			// 			message: res.msg
			// 		})
			// 	}
			// })

		},
		changeAliState(e) {
			console.log(e,'==okl')
			this.dataDetails.state= e?0:1
			console.log(this.dataDetails.state,'this.dataDetails.state')
		},
		getMobiles() {
			let data = {
				page: 1,
				size: 1000,
				pay_type:this.pay_type,
			}
			this.$api.getMobiles(data).then(res => {
				if (res.status === 1) {
					this.mobileData = res.data.data;
				} else {
					this.$message({
						type: 'error',
						message: res.msg
					})
				}
			}).catch(error => {})
		},
		getAccount() {
			let data = {
				parvalue: this.dataDetails.match_gold,
			}
			
			this.$api.getHd(data).then(res=> {
				if(res.status == 1){
					this.dataDetails.account = res.data.account;
					this.dataDetails.hd_order_id = res.data.order_id;
					this.notUsedTime = parseInt(res.data.not_used_time); // 获取倒计时时间
					this.startCountdown(); // 开始倒计时
				}
				else{
					this.$message({
						type: 'error',
						message: res.msg
					})
				}					
			})
		},
		// 开始倒计时
		startCountdown() {
			this.canGetAccount = false;
			this.timer = setInterval(() => {
				if (this.notUsedTime > 0) {
					this.notUsedTime--;
				} else {
					clearInterval(this.timer);
					this.canGetAccount = true; // 允许重新获取账号
				}
			}, 1000);
		},
	    close() {
			this.$emit('close'); // 触发父组件监听的 close 事件
	    	this.dialogVisible = false
			this.params.dialogVisible = false
	    	this.dialogloading = false
	    	this.dialogTitle = ''
	    	this.dialogType = ""
	    	this.item = {}
	    	this.storeInfo = {}
	    	this.aliState = true
	    	this.fileurl = null
	    	this.fileurlFriends = null
	    	this.dataDetails = {
	    		true_name: "",
	    		telephone: "",
	    		state: 0,
	    		account: '',
	    		nickname: '',
	    		callback_id: '',
	    		qrcode_url: '',
	    		frcode_url:'',
	    		ali_userid:'',
	    		frcode_link:'',
	    		qrcode_link:'',
	    		eve_used_num:'',
	    		eve_used_money:'',
	    		gold: '',
	    		total_num: '',
	    		match_gold: '100',
	    	}
	    	clearInterval(this.timer); // 停止倒计时
	    },
		resolveQR(event) {
			this.uploadQrcode(event.file)
			const result = this.getQrUrl(event.file)
			result.then(res => {

				if (res.data) {
					this.dataDetails.qrcode_url = res.data
					this.$message({
						type: 'success',
						message:'识别二维码成功!'
					})
				} else {
					this.$message({
						type: 'error',
						message:'识别二维码失败, 请重新上传!'
					})
				}
			}).catch(err => {
				console.log(err, '识别异常')
			})
		},
		resolveQRFriends(event){			
			this.uploadQrcode(event.file,'friends')
			const result = this.getQrUrl(event.file,'friends')
			result.then(res => {
				if (res.data) {
					this.dataDetails.frcode_url = res.data
					this.$message({
						type: 'success',
						message:'识别二维码成功!'
					})
				} else {
					this.$message({
						type: 'error',
						message:'识别二维码失败, 请重新上传!'
					})
				}
			}).catch(err => {
				console.log(err, '识别异常')
			})

		},
		changeImg(file, fileList) {
			this.fileList = [file.raw]
			this.resolveQR(file.raw)

		},
		getQrUrl(file,type) {
			var url = null;
			if (window.createObjectURL) { // basic
				url = window.createObjectURL(file);
			} else if (window.URL) { // mozilla(firefox)
				url = window.URL.createObjectURL(file);
			} else if (window.webkitURL) { // webkit or chrome
				url = window.webkitURL.createObjectURL(file);
			}
			if(type == 'friends'){
				this.fileurlFriends = url
			}else{
				this.fileurl = url
			}
			
			// 初始化
			const qr = new QrCode()
			// 解析二维码，返回promise
			return qr.decodeFromImage(url)
		},
		showBigImg(title,img){
			//查看大图
			this.qrImgTitle = title
			if(img){
				this.showImg = img
				this.innerVisibleImg = true
			}			
		},
		uploadQrcode(file,type){
			let formData = new FormData()
			formData.append('file', file)
			formData.append('username',local.get('bpcusername'))
			formData.append('tid',local.get('bpctid'))
			this.disLoading = true
			this.$api.uploadQrcode(formData).then(res => {
				this.disLoading = false
				if (res.status === 1) {
					if(type == 'friends'){
						this.dataDetails.frcode_link = res.data
					}else{
						this.dataDetails.qrcode_link = res.data
					}
					this.$message({
						type: 'success',
						message: res.msg
					})
				}else{
					if(type == 'friends'){
						this.dataDetails.frcode_link = ''
					}else{
						this.dataDetails.qrcode_link = ''
					}
					this.$message({
						type: 'error',
						message: res.msg
					})
				}
				
			})
				
		},
		opened(){
			// let doc = document.getElementById('myDialog')
			// var domName = document.querySelector('#myDialog .el-dialog');
			// 	domName.style.background = 'transparent'
			// 	domName.style['box-shadow'] = 'none'
		},
		querySearch(queryString, cb) {
        var restaurants = this.restaurants;
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      createFilter(queryString) {
        return (restaurant) => {
          return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
		
		
	  },
	};
</script>


<style scoped="scoped" lang="scss">
@import '@/style/variables.scss';

.dataKey {
	display: inline-block;
	flex: 0 0 100px;
	text-align: left;
}

.qrIcon {
	display: inline-block;
	background: url(../assets/image/qr.png);
	width: 30px;
	height: 30px;
	background-size: 100% 100%;
}

.errorDiv {
	white-space: nowrap;
	z-index: 2;
	position: absolute;
	top: 0;
	font-size: 10px;
	color: $danger
}

#uploadImg {
	display: none;
}

.upload-img {
	width: 100px;
	height: 100px;
}

.popup {
	position: fixed;
	left: 0;
	top: 0;
	display: none;
	background: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100%;
	z-index: 9999;

	&.show {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.qrcode {
		display: inline-block;

		img {
			width: 400px;
			height: 400;
			background-color: #fff; //设置白色背景色
			padding: 20px; // 利用padding的特性，挤出白边
			box-sizing: border-box;
		}
	}
}
.image-dialog{
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
}

.flexX.flexcenter.mb20 >>> .el-input__inner {
    font-size: 1.2rem;
}


::v-deep .el-input__inner {
    font-size: 1.25rem; /* 设置你想要的字体大小 */
}
</style>