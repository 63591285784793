<template>
    <div>
        <el-button class="mr20" size="mini" type="warning" @click="stateAll(1)"
            :disabled="disLoading || operating || tableDataLength == 0 || multipleSelection.length == 0">批量停用
        </el-button>
        <el-button class="mr20" size="mini" type="success" @click="stateAll(0)"
            :disabled="disLoading || operating || tableDataLength == 0 || multipleSelection.length == 0">批量启用
        </el-button>
        <el-button size="mini" type="danger" @click="deleteAll()" 
            :disabled="disLoading || operating || tableDataLength == 0 || multipleSelection.length == 0">批量删除
        </el-button>
    </div>
</template>

<script>
import local from '@/util/local'
import {
	Message
} from 'element-ui'
export default {
    name: 'batchOperate',
    props: {
        multipleSelection: {
            type: Array,
            default: () => []
        },
        disLoading:{
            type: Boolean,
            default: false,
        },
        tableDataLength:{
            type: Number,
            default: 0
        }
    },
	data() {
		return {
            operating:false,

        }
	},
	created() {
	},
	filters: {
	},
    methods:{
        //批量启停点击事件处理数据
        stateAll(state) {
			let selectArr = this.multipleSelection.filter(item => {
				if (item.id) {
					return item
				}
			})
			let dataId = selectArr.map(item => {
				return item.id
			})
			let data = {
				state: state,
				ids: dataId.join(',')
			}
			console.log(data)
			this.setStoreQrState(data)
		},
        //批量启停接口提交
        setStoreQrState(data) {
			this.$confirm(`确定改变选择收款码的启停状态吗?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.operating = true
				this.$api.batchState(data).then(res => {
					console.log("res")
					this.operating = false
					if (res.status == 1) {
                        this.$emit("updateList")
						// this.alipayList()
					} else {
						this.errorArr = res.data
					}
				}).catch(error => {
					this.operating = false
				})
			}).catch(() => {
				this.operating = false
				this.$message({
					type: 'info',
					message: '已取消操作'
				});
			});
		},
        //批量删除点击事件  处理数据
        deleteAll() {
			let selectArr = this.multipleSelection.filter(item => {
				if (item.id) {
					return item
				}
			})
			let dataId = selectArr.map(item => {
				return item.id
			})
			let data = {
				ids: dataId.join(',')
			}
			console.log(data)
			this.deleteStoreQr(data)
		},
        //批量删除接口提交
        deleteStoreQr(data) {
			this.$confirm(`确定删除支付宝吗?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.operating = true
				this.$api.batchDeleteAlipays(data).then(res => {
					this.operating = false
					if (res.status == 1) {
                        this.$emit("updateList")
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
						this.errorArr = res.data
					}
				}).catch(error => {
					this.operating = false
				})
			}).catch(() => {
				this.operating = false
				this.$message({
					type: 'info',
					message: '已取消操作'
				});
			});
		},
    },
	watch: {

	},

};
</script>

<style scoped="scoped" lang="scss">
@import '@/style/variables.scss';
</style>