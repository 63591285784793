<template>
	<div>
	  <el-tabs v-model="activeName" @tab-click="handleClick">
		<el-tab-pane label="拼多多" name="pdd"></el-tab-pane>
		<el-tab-pane label="淘宝零钱" name="tblq"></el-tab-pane>
		<el-tab-pane label="支付宝余额充值" name="zfbYeCz"></el-tab-pane>
		<el-tab-pane label="淘宝" name="tb"></el-tab-pane>
	  </el-tabs>
		<div class="queryBox mb10">
			<div class="mb20"><el-button size="mini" type="primary" @click="newMobile()" class="mr20">新增</el-button></div>
			<el-button size="mini" plain class="mr20" @click="setDate(-1)" :disabled="disLoading">前一天</el-button>
			<el-date-picker size="mini" v-model="date" type="daterange" range-separator="至" start-placeholder="开始日期"
				end-placeholder="结束日期" class="mr20"></el-date-picker>
			<el-button size="mini" plain class="mr20" @click="setDate(1)" :disabled="disLoading">后一天</el-button>
				<el-button size="mini" type="primary" :icon="disLoading ? 'el-icon-loading' : 'el-icon-search'" @click="getData" :disabled="disLoading">查询</el-button>
				<el-button size="mini" type="danger" @click="deleteSelectedRows" :disabled="!multipleSelection.length">批量删除</el-button>
    
		</div>
		<template>
			<el-table ref="multipleTable" size="medium" :max-height="height" :data="tableData" stripe v-loading="loading" :show-overflow-tooltip="true" @selection-change="handleSelectionChange">
				<el-table-column  type="selection" width="45"></el-table-column>
				<el-table-column type="index" label="序号"  width="60px"></el-table-column>
				<el-table-column label="机位" >
					<template slot-scope="scope">
						{{scope.row.name}}
					</template>
				</el-table-column>
				<el-table-column label="总计" >
					<template slot-scope="scope">
						<div v-if="scope.row.id" class="flexY flexStart">
							<div class="flexX flexStart">
								<div class="yctable" >笔数: {{scope.row.total_count?scope.row.total_count:0}}</div>
							</div>
							<div class="flexX flexStart">
								<div class="yctable" >金额: {{scope.row.mobile_sum_gold?scope.row.mobile_sum_gold:0.00 | tofixed}}</div>
							</div>
						</div>
					</template>
					
				</el-table-column>
				<el-table-column label="成功" >
					<template slot-scope="scope">
						<div v-if="scope.row.id" class="flexY flexStart">
							<div class="flexX flexStart">
								<div class="yctable" >笔数: {{scope.row.success_count?scope.row.success_count:0}}</div>
							</div>
							<div class="flexX flexStart">
								<div class="yctable" >金额: {{scope.row.success_money?scope.row.success_money:0.00 | tofixed}}</div>
							</div>
						</div>
					</template>
					
				</el-table-column>
				<el-table-column label="支付超时" >
					<template slot-scope="scope">
						<div v-if="scope.row.id" class="flexY flexStart">
							<div class="flexX flexStart">
								<div class="yctable" >笔数: {{scope.row.faild_count?scope.row.faild_count:0}}</div>
							</div>
							<div class="flexX flexStart">
								<div class="yctable" >金额: {{scope.row.faild_money?scope.row.faild_money:0.00 | tofixed}}</div>
							</div>
						</div>
					</template>
					
				</el-table-column>
				<el-table-column label="未配单" >
					<template slot-scope="scope">
						<div v-if="scope.row.id" class="flexY flexStart">
							<div class="flexX flexStart">
								<div class="yctable" >笔数: {{scope.row.unmatch_count?scope.row.unmatch_count:0}}</div>
							</div>
							<div class="flexX flexStart">
								<div class="yctable" >金额: {{scope.row.unmatch_money?scope.row.unmatch_money:0.00 | tofixed}}</div>
							</div>
						</div>
					</template>
					
				</el-table-column>
				<el-table-column label="支付中" >
					<template slot-scope="scope">
						<div v-if="scope.row.id" class="flexY flexStart">
							<div class="flexX flexStart">
								<div class="yctable" >笔数: {{scope.row.processing_count?scope.row.processing_count:0}}</div>
							</div>
							<div class="flexX flexStart">
								<div class="yctable" >金额: {{scope.row.processing_money?scope.row.processing_money:0.00 | tofixed}}</div>
							</div>
						</div>
					</template>
					
				</el-table-column>
				<!-- <el-table-column label="可用额度" >
					<template slot-scope="scope">
						<el-link type="primary"><el-tag type="success">{{scope.row.enable_money}}</el-tag></el-link>
					</template>
				</el-table-column>
				<el-table-column prop="today_tc" label="当日提成" ></el-table-column> -->
				<el-table-column label="操作" >
					<template slot-scope="scope">
						
						<el-link class="mr20" size="mini" type="primary" @click="showAddItem(scope.row)">添加码</el-link>
						<el-link class="mr20" type="primary" @click="editMobileOpen(scope.row)">编辑</el-link>
						
						
						<el-popover
						  placement="left"
						  width="160"
						  v-model="scope.row.delvisible">
						  <p>确定删除吗？</p>
						  <div style="text-align: right; margin: 0">
						    <el-button size="mini" type="text" @click="scope.row.delvisible = false">取消</el-button>
						    <el-button type="primary" size="mini" @click="delMobile(scope.row)">确定</el-button>
						  </div>
						  <el-link size="mini" type="danger" slot="reference">删除</el-link>
						</el-popover>
					</template>
				</el-table-column>

			</el-table>
		</template>
		<div class="flexX  mt20 pageBox">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page" :page-sizes="pageSizesArr" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
		<el-dialog center class="dialog" top="150px" title="" :visible.sync="dialogVisible" @close="close" width='30%' :close-on-click-modal="closeModal" :title="dialogTitle">
			<div class="formBox" v-loading="dialogloading">
				<div class="" v-if="dialogType == 'new' || dialogType == 'edit'">
					<div class="flexX flexcenter mb20">
						<span>机位名称：</span>
						<el-input class="flex1" type="text" size="mini" placeholder="请输入机位名称" v-model="item.name"></el-input>
					</div>
					<div  v-if="this.pay_type == 105">
						<div class="flexX flexcenter mb20">
							<span>二维码：</span>
							<div class="flex1 flexX flexcenter">
								<el-button class="mr20" type="primary" size="mini" @click="getTbSqUrl" :disabled='ckQrData.loading'>点击授权</el-button>
								<div id="qr" class="qrcode" ref="qrCodeUrl"></div>
							</div>
						</div>
						<div class="flexX flexcenter mb20">
							<span>淘宝CK：</span>
							<el-input class="flex1" type="textarea" :rows="12" size="mini" placeholder="请输入淘宝CK" v-model="item.tb_cookie"></el-input>
						</div>
					</div>
					
				</div>
				<div class="textCenter">
					<el-button size="mini" type="primary" @click="sure">确定</el-button>
					<el-button size="mini" @click="close">关闭</el-button>
				</div>
			</div>
		</el-dialog>
		<!-- 短信验证输入框 -->
		<el-dialog center class="smsCodeDialog" top="150px" title="" :visible.sync="smsCodeVisible" @close="smsCodeClose" width='30%' :close-on-click-modal="closeModal" title="提示">
			<div class="formBox">
				<div class="smsCodeTitle mb20">请输入您： {{phone }} 手机号码。收到的【阿里巴巴】登录验证短信！4位数字验证码，进行授权登录（没有风险）</div>
				<div class="mb20">
					<el-input class="flex1" type="text" size="mini" placeholder="请输入4位数字短信验证码" v-model="sms_code"></el-input>
				</div>
				<div class="textCenter">
					<el-button size="mini" type="primary" @click="getTbCkBySms">确定</el-button>
					<el-button size="mini" @click="smsCodeClose">关闭</el-button>
				</div>
			</div>
		</el-dialog>
		
		
		<AddTao v-if="showAddItemComponent && activeName != 'zfbYeCz'" @close="handleClose"  :params="params" />
		<AddTao v-if="showAddItemComponent && activeName == 'zfbYeCz'" @close="handleClose"  :params="params" :matchMoney="matchMoney"/>
		
	</div>
</template>

<script>
	import local from '@/util/local'
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isMobile,
		isIncludeHanzi
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	import AddTao from '@/components/AddTaoHfZfb.vue';
	
import QRCode2 from 'qrcodejs2'
	export default {
		components: {
			AddTao, // 注册组件
		  },
		data() {
			return {
				loading: false,
				disLoading: false,
				dialogType:"",
				closeModal:false,
				dialogVisible:false,
				dialogloading:false,
				dialogTitle:'',
				height: 0,
				tableData: [],
				pageSizesArr: [20, 50, 100, 200],
				pageSize: 20,
				total: 0,
				page: 1,
				item:{
					name: '',
					tb_cookie: '',
				},
				feiArr:[],
				parentArr: [{
				}],
				date: null,
				// 删除
				delvisible: false,
				multipleSelection: [] ,// 存储多选选择的数据
				// 新增码组件
				showAddItemComponent: false, // 控制组件显示状态
				params:{},
				// 标签页
				activeName:'pdd',
				pay_type: '',
				ckQrData:{loading:false},
				//短信验证码
				sms_code:'',
				smsCodeVisible:false,
				bySms :{},
				phone:'',
				//支付宝余额充值匹配金额
				matchMoney: [
				{
					key: '100',
					value: "100"
				},
				{
					key: '200',
					value: "200"
				},
				{
					key: '300',
					value: "300"
				},
				{
					key: '500',
					value: "500"
				},
				{
					key: '800',
					value: "800"
				},
				{
					key: '1000',
					value: "1000"
				},
				{
					key: '1500',
					value: "1500"
				},
				{
					key: '2000',
					value: "2000"
				},
			],
			};
		},
		created() {
			
			if(this.activeName == 'tb'){
				this.pay_type = 103
			}
			if(this.activeName == 'pdd'){
				this.pay_type = 104
			}
			if(this.activeName == 'tblq'){
				this.pay_type = 105
			}if(this.activeName == 'zfbYeCz'){
				this.pay_type = 107
			}
			
			this.date = [this.$util.timestampToTime(new Date().getTime(), true), this.$util.timestampToTime(new Date().getTime(),true)];
			this.mobiles()
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
			})
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					let val = Math.floor(((value * 1) * 100).toPrecision(12))
					return ((val / 100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			handleClick(tab, event) {
				if(this.activeName == 'tb'){
					this.pay_type = 103
				}
				if(this.activeName == 'pdd'){
					this.pay_type = 104
				}
				if(this.activeName == 'tblq'){
					this.pay_type = 105
				}
				if(this.activeName == 'zfbYeCz'){
					this.pay_type = 107
				}
				this.mobiles()
			},
			showAddItem(row) {
				this.showAddItemComponent = true;
				this.params = {
					'type':'new',
					'dialogVisible': true,
					'mobile_id': row.id,
					'pay_type': this.pay_type,
					}
			},
			handleClose() {
				this.showAddItemComponent = false; // 关闭组件
				this.mobiles(); // 调用获取页面数据的方法
			},
			handleSelectionChange(val) {
			  this.multipleSelection = val;
			},
			deleteSelectedRows() {
				this.$confirm('确定要删除选中的数据吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				  }).then(() => {
					const ids = this.multipleSelection.map(item => item.id); // 假设每行数据有唯一 id
					// 批量删除逻辑
						let data = {
							ids: ids.join(','),
						}
					this.$api.batchDelMobile(data).then(res=>{
						this.dialogloading = false
						if(res.status == 1){
							this.mobiles()		
							this.$message({
								type: 'success',
								message: res.msg
							})
						}else{
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error=>{
						this.dialogloading = false
					})
				  }).catch(() => {
					// 取消删除
					this.$message.info('删除已取消');
				  });
			  
			  
			},
			delMobile(row){
				let data = {
					id: row.id,
				}
				this.dialogloading = true
				this.$api.delMobile(data).then(res=>{
					this.dialogloading = false
					if(res.status == 1){
						row.delvisible = false;
						this.mobiles()		
						this.$message({
							type: 'success',
							message: res.msg
						})
					}else{
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error=>{
					this.dialogloading = false
				})
			},
			setDate(day) {
				let data = this.date ? this.date[0] : this.$util.timestampToTime(new Date().getTime(), true)
				let newDate = this.$util.getLastDate(data, day);
				this.date = [newDate, newDate]
				this.getData()
			},
			handleSizeChange(val) {
				this.pageSize = val
				this.page = 1
				this.mobiles()
			},
			handleCurrentChange(val) {
				this.page = val
				this.mobiles()
			},
			//搜索按钮
			getData() {
				this.page = 1;
				this.mobiles();
			},
			mobiles() {
				this.tableData = []
				let data = {
					page: this.page,
					size: this.pageSize,
					pay_type: this.pay_type,
					is_check_data: 1,
					start_time: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[0])), true) : '',
					end_time: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[1])), true) : '',
				}
				this.$api.getMobiles(data).then(res => {
					if (res.status === 1) {
						// this.tableData = res.data.data;
						this.tableData = res.data.data.map(item => ({
						      ...item,
						      delvisible: false  // 初始化每行的弹出框控制属性
						    }));
						this.total = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {})
			},
			//新增粉丝按钮
			newMobile(){
				this.item={
					name:"",
				}
				this.dialogType = "new"
				this.dialogTitle = "新增机位"
				this.dialogVisible= true;
			},
			//编辑粉丝按钮
			editMobileOpen(item){
				this.item = item
				this.dialogType = "edit"
				this.dialogTitle = "编辑机位"
				this.dialogVisible= true
			},
			close(){
				this.item = {}
				this.dialogTitle = ""
				this.dialogType=""
				this.dialogVisible= false;
				this.ckQrData = {loading:false}
			},
			sure(){
				if(this.dialogType == "new"){
					//新增粉丝
					this.addMobile()
				}
				if(this.dialogType == "edit"){
					//编辑粉丝
					this.editMobile()
				}
			},
			addMobile(){
				if (isEmpty(this.item.name, "请输入机位名称")) {
					return
				}
				
				let data = {
					name: this.item.name,
					pay_type: this.pay_type,
					tb_cookie: this.item.tb_cookie
				}
				this.dialogloading = true
				this.$api.addMobile(data).then(res=>{
					this.dialogloading = false
					if(res.status == 1){
						this.close()
						this.mobiles()						
					}else{
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error=>{
					this.dialogloading = false
				})
			},
			editMobile(){
				if (isEmpty(this.item.name, "请输入机位名称")) {
					return
				}
				
				let data = {
					id: this.item.id,
					name: this.item.name,
					tb_cookie: this.item.tb_cookie,
				}
				this.dialogloading = true
				this.$api.setMobile(data).then(res=>{
					this.dialogloading = false
					if(res.status == 1){
						this.close()
						this.mobiles()						
					}else{
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error=>{
					this.dialogloading = false
				})
			},
			//获取授权二维码
			getTbSqUrl(){
				let data = {}
				this.ckQrData={
					loading: true
				}
				this.$api.getTbSqUrl({}).then(res=>{
					if(res.status == 0){
						//获取失败
						this.$message({
							type: 'error',
							message: res.msg
						})						
						this.ckQrData.loading = false
					}else if(res.status == 1){
						this.ckQrData =  res.data
						this.getQrImg(this.ckQrData.qrAuthUrl)
						this.ckQrData.loading = false
						this.getTbCk()
					}
				}).catch(error=>{
					this.ckQrData.loading = false
				})
			},
			getQrImg(url) {
				this.showPopup = true
				document.getElementById('qr').innerHTML = '';
				var qrcode = new QRCode2(this.$refs.qrCodeUrl, {
					text: url, // 需要转换为二维码的内容
					width: 220,
					height: 220,
					colorDark: '#000000',
					colorLight: '#ffffff',
					correctLevel: QRCode2.CorrectLevel.H
				})
			},
			getTbCk(){
				let data = {
					t: this.ckQrData.t,
        			ck: this.ckQrData.ck,
					MergeCookies: this.ckQrData.MergeCookies,
				}
				this.$api.getTbCk(data).then(res=>{
					if(res.status == 1){
						let resData = res.data
						if(resData.is_continue == 1){
							//定时循环请求   等待用户授权
							setTimeout(()=>{
								this.getTbCk()
							}, 3000)
						}else if(resData.is_continue == 0){
							//二维码授权成功 
							//进入短信授权
							if(resData.is_sms_ver == 1){
								//进入短信授权
								this.smsCodeVisible = true
								this.bySms.MergeCookies = resData.MergeCookies
								this.bySms.SmsVerInfo = resData.SmsVerInfo
								this.phone =JSON.parse(resData.SmsVerInfo).phone
							}else{
								//无需短信，授权成功
								this.item.tb_cookie = resData.Cookies
								this.$forceUpdate()
								this.$message({
									type: 'success',
									message: resData.msg
								})
							}
						}
					}else{
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			getTbCkBySms(MergeCookies,SmsVerInfo){
				let data = {
					MergeCookies:this.bySms.MergeCookies,
					SmsVerInfo: this.bySms.SmsVerInfo,
					sms_code:this.sms_code
				}
				this.$api.getTbCkBySms(data).then(res=>{
					let resData = res.data
					if(res.status == 1){
						this.item.tb_cookie = resData.Cookies
						this.$forceUpdate()
						this.$message({
							type: 'success',
							message: resData.msg
						})
						this.smsCodeClose()
					}else{
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			smsCodeClose(){
				this.smsCodeVisible = false
				this.bySms = {}
				this.phone = ''
				this.sms_code = ''
			}
		
			
		},
		watch: {
			total(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
				})
			}
		},
	};
</script>

<style scoped="scoped" lang="scss">
	.formBoxbd {
		width: 80%;
		margin: 0 auto;
	}
</style>
<style type="text/css">
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
