<template>
	<div>
		<div class="queryBox ">
			<div class="mb20"><el-button size="mini" type="primary" @click="newFans()" class="mr20">新增</el-button></div>
			<!-- <div class="mb20"><span>我的额度：<el-tag type="success">{{$local.get('bpcUserinfo').enable_money}}</el-tag></span></div> -->
			<div class="flexX flexcenter pt10 pb10 pl10 pr10 backGrey ">
				<el-breadcrumb separator-class="el-icon-arrow-right" class="fs20" >
					<el-breadcrumb-item v-for="(item,index) in parentArr" v-if="index < parentArr.length-1" ><span @click="showparent(item)" class="breadfs">{{item.name}}</span></el-breadcrumb-item>
					<!-- <el-breadcrumb-item v-for="(item,index) in parentArr" v-if="index < parentArr.length-1" @click="showparent(item)" ><span ><el-link type="primary">{{item.name}}</el-link></span></el-breadcrumb-item> -->
					<el-breadcrumb-item  v-for="(item,index) in parentArr" v-if="index == parentArr.length-1">{{item.name}}</el-breadcrumb-item>
					
				</el-breadcrumb>
				<!-- <div v-for="(item,index) in parentArr" v-if="index < parentArr.length-1" @click="showparent(item)">
					<el-link type="primary">{{item.name}}</el-link>
					<i class="el-icon-d-arrow-right" v-if="index < parentArr.length-1"></i>
				</div>
				<div v-for="(item,index) in parentArr" v-if="index == parentArr.length-1">{{item.name}}</div> -->
			</div>
			
		</div>
		<template>
			<el-table size="medium" :max-height="height" :data="tableData" stripe v-loading="loading" :show-overflow-tooltip="true">
				<el-table-column type="index" label="序号"  width="80px"></el-table-column>
				<el-table-column label="账号" >
					<template slot-scope="scope">
						<el-link type="primary" @click="showChild(scope.row)">{{scope.row.name}}</el-link>
					</template>
				</el-table-column>
				<el-table-column label="余额" >
					<template slot-scope="scope">
						<el-tag type="success">{{scope.row.enable_money}}</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="advance_money" label="押金" ></el-table-column>
				<el-table-column prop="children_count" label="下级粉丝" ></el-table-column>
				<el-table-column prop="today_in" label="今日收款" ></el-table-column>
				<el-table-column prop="today_tc" label="今日提成" ></el-table-column>
				<el-table-column prop="today_yj" label="今日佣金" ></el-table-column>
				<el-table-column prop="yesterday_in" label="昨日收款" ></el-table-column>
				<el-table-column prop="yesterday_tc" label="昨日提成" ></el-table-column>				
				<el-table-column prop="yesterday_yj" label="昨日佣金" ></el-table-column>
				<el-table-column label="操作" width="150px">
					<template slot-scope="scope">
						<el-link class="mr20" type="warning" v-if="false" @click="showChildQR(scope.row)">收款码</el-link>
						<el-link class="mr20" type="primary" v-if="parentArr.length==1" @click="editFans(scope.row)">编辑</el-link>
						<el-link class="mr20" type="primary" v-if="parentArr.length==1" @click="showChild(scope.row)">下级粉丝</el-link>
					</template>
				</el-table-column>

			</el-table>
		</template>
		<div class="flexX  mt20 pageBox">
		
		</div>
		<el-dialog center class="dialog" top="15px" title="" :visible.sync="dialogVisible" @close="close" :width="dialogType=='showQr'?'50%':'50%'"
		 :close-on-click-modal="closeModal" :title="dialogTitle">
			<div class="formBox" v-loading="dialogloading">
				<div class="" v-if="dialogType == 'new' || dialogType == 'edit'">
					<el-descriptions class="margin-top"  :column="3" size="medium">					
						<el-descriptions-item label="姓名">
							<div>
								<el-input v-if="dialogType == 'new'" class="flex1" type="text" size="mini" placeholder="请输入姓名" v-model="item.name" style="max-width: 200px"></el-input>
								<span v-if="dialogType == 'edit'">{{item.name}}</span>
							</div>
						</el-descriptions-item>
						<el-descriptions-item label="登录账号">
							<div>
								<el-input v-if="dialogType == 'new'" class="flex1" type="text" size="mini" placeholder="请输入登录账号" v-model="item.username" style="max-width: 200px"></el-input>
								<span v-if="dialogType == 'edit'">{{item.username}}</span>
							</div>
						</el-descriptions-item>
						<el-descriptions-item label="联系电话"> 
							<div>
								<el-input v-if="dialogType == 'new'" class="flex1" type="text" size="mini" placeholder="请输入联系电话" v-model="item.telephone" style="max-width: 200px"></el-input>
								<span v-if="dialogType == 'edit'">{{item.telephone}}</span>
							</div>
						</el-descriptions-item>
						<el-descriptions-item label="密码" v-if="dialogType == 'new'"> 
							<div>
								<el-input class="flex1" type="password" size="mini" placeholder="请输入登录密码" v-model="item.password" style="max-width: 200px"></el-input>
							</div>
						</el-descriptions-item>
						<el-descriptions-item label="确认密码"v-if="dialogType == 'new'">
							<div>
								<el-input class="flex1" type="password" size="mini" placeholder="请再次输入登录密码" v-model="item.password1" style="max-width: 200px"></el-input>
							</div>
						</el-descriptions-item>
					</el-descriptions>

					<el-divider></el-divider>

					<el-table  fit :max-height="height2" :data="feiArr"  stripe v-loading="loading" :show-overflow-tooltip="true">
						<el-table-column label="启/停状态" width="120px">
							<template slot-scope="scope">
								<el-checkbox v-model="scope.row.stateBoolean" :label="scope.row.stateBoolean?'开启':'关闭'" border></el-checkbox>
							</template>
						</el-table-column>
						<el-table-column prop="pay_type" label="通道编号"></el-table-column>
						<el-table-column prop="name" label="通道名称">
							<template slot-scope="scope">
								<span>{{scope.row.name}} (‰)</span>
							</template>
						</el-table-column>
						<el-table-column prop="fee" label="费率">
							<template slot-scope="scope">
								<el-input class="flex1" type="text" size="mini" :placeholder="`费率范围:${scope.row.min_fee}-${scope.row.max_fee}`" v-model="scope.row.fee"></el-input>
							</template>
						</el-table-column>
						<el-table-column prop="fee" label="费率范围">
							<template slot-scope="scope">
								<span>{{scope.row.min_fee}} - {{scope.row.max_fee}}</span>
							</template>
						</el-table-column>
					</el-table>



					<!-- <div class="flexX flexcenter mb20" v-for="item in feiArr">
						<span class="label">{{item.name}}(‰)：</span>
						<el-input class="flex1" type="text" size="mini" :placeholder="`费率范围:${item.min_fee}-${item.max_fee}`" v-model="item.fee"></el-input>

					</div> -->
					
				</div>
				<div v-if="dialogType == 'showQr'" class="mb20">
					<template>
						<el-table v-if="item.groupMoney" size="mini" :data="item.groupMoney" stripe :show-overflow-tooltip="true">
							<el-table-column type="index" label="序号"  width="80px"></el-table-column>
							<el-table-column label="金额" >
								<template slot-scope="scope">
									<span>{{scope.row.money}}</span>
								</template>
							</el-table-column>
							<el-table-column prop="total_count" label="总码数" ></el-table-column>
							<el-table-column prop="qr_enable_count" label="可用数量" ></el-table-column>
							<el-table-column prop="qr_disable_count" label="自动停码数" ></el-table-column>							
							<el-table-column label="总金额" >
								<template slot-scope="scope">
									<span>{{scope.row.total_money}}</span>
								</template>
							</el-table-column>
							<el-table-column label="可用金额" >
								<template slot-scope="scope">
									<span>{{scope.row.qr_enable_money}}</span>
								</template>
							</el-table-column>					
						</el-table>
					</template>
				</div>
				
			</div>
			<div class="textCenter footerDialog">
					<el-button size="mini" type="primary" @click="sure()" v-if="dialogType !='showQr'">确定</el-button>
					<el-button size="mini" @click="close">关闭</el-button>
				</div>
		</el-dialog>
		
		<!-- 新增下级粉丝额度 -->
		<el-dialog width="800px" center top="150px" title="增加额度" :visible.sync="adddialogVisible" @close="addMoneyclose" :close-on-click-modal="false">
			<div class="formBoxbd" v-loading="adddialogloading">
				<div>
					<el-form label-position="right" label-width="100px">
						<el-form-item label="增加额度">
							<el-input v-model="addMoney" placeholder="请输入增加额度"></el-input>
						</el-form-item>
					</el-form>
				</div>
				<div class="textCenter">
					<el-button type="primary" @click="addEnableMoney(addItem)">增加</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import local from '@/util/local'
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isMobile,
		isIncludeHanzi
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				loading: false,
				disLoading: false,
				dialogType:"",
				closeModal:false,
				dialogVisible:false,
				dialogloading:false,
				dialogTitle:'',
				height: 0,
				height2:100,
				tableData: [],
				pageSizesArr: [20, 50, 100, 200],
				pageSize: 20,
				total: 0,
				page: 1,
				item:{},
				feiArr:[],
				parentArr: [{
				}],
				// 增加额度
				addMoney: '', // 增加的额度
				adddialogVisible: false, // 增加额度弹窗
				adddialogloading: false,
				addItem: {}

			};
		},
		created() {
			// this.date = [this.$util.timestampToTime(new Date().getTime(), true), this.$util.timestampToTime(new Date().getTime(),true)];
			this.infoTotal();
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
			})
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					let val = Math.floor(((value * 1) * 100).toPrecision(12))
					return ((val / 100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			// 增加额度
			showChangeMoney(item, index) {
				this.addItem = item
				this.adddialogVisible = true
				
				if (JSON.stringify(this.closeStateObj) == '{}') {
					this.closeStateFun()
				}

			},
			// 增加额度
			addEnableMoney(item) {
				let data = {
					carder_id: item.id,
					amount: this.addMoney
				}
				this.adddialogloading = true
				this.$api.TransferAdvanceMoney(data).then(res => {
					this.adddialogloading = false
					if (res.status == 1) {
						this.fans()
						this.infoTotal()
						this.addMoneyclose()
						this.$message({
							type: 'success',
							message: '增加成功'
						});
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						});
					}
				}).catch(error => {
					this.adddialogloading = false
				})
				
			},
			// 驳回
			addMoneyclose() {
				this.addItem = {}
				this.adddialogloading = false
				this.adddialogVisible = false
				this.addMoney = ''
			},
			//搜索按钮
			getData() {
				this.page = 1;
				this.fans();
			},
			infoTotal(){
				this.$api.infoTotal({}).then(res=>{
					if(res.status == 1){
						let data = res.data
						this.$local.set('bpcUserinfo',res.data);
						this.parentArr[0] = {
							id:data.id,
							name:data.name,
							enable_money: data.enable_money
						}
						this.fans();
					}
				})
			},
			fans(item, isList) {
				let data = {
					type: this.$payType
				}
				if (item) {
					data.carder_id = item.id;
				}
				this.$api.fans(data).then(res => {
					if (res.status === 1) {
						if (item) {
							// if(res.data.length > 0){
							this.tableData = res.data;
							if (isList) {
								this.parentArr.push(item)
							} else {
								for (let i = 0; i < this.parentArr.length; i++) {
									if (this.parentArr[i].id == item.id) {
										this.parentArr.splice(i + 1, this.parentArr.length - 1);
										break
									}
								}
							}
							// }else{

							// }
						} else {
							this.tableData = res.data
						}

						// this.tableData = res.data.data;
						// this.total = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {})
			},
			showparent(item) {
				this.fans(item, false)
				this.item = item
			},
			showChild(item) {
				this.fans(item, true)
				this.item = item
			},
			info(){
				let data = {
					id:this.item.id
				}
				this.$api.info(data).then(res=>{
					if(res.status == 1){
						this.item = res.data
					}
				})
			},
			feeList(id){
				let data = {}
				if(id){
					data.id = id
				}
				this.loading = true
				this.$api.feeList(data).then(res=>{
					this.loading = false
					if(res.status == 1){
						res.data.forEach(item => {
							item.stateBoolean = item.state == 1 ? true : false
						})
						this.feiArr = res.data
						this.$nextTick(() => {
							let formBox = document.getElementsByClassName('formBox')[0].offsetHeight;
							let margintop = document.getElementsByClassName('margin-top')[0].offsetHeight;
							let dialogHeader = document.getElementsByClassName('el-dialog__header')[0].offsetHeight;
							let eldivider = document.getElementsByClassName('el-divider')[0].offsetHeight;
							
							let documentHeight = document.documentElement.clientHeight
							console.log(formBox,margintop,'formBox,margintop')
							this.height2 = documentHeight - margintop -  dialogHeader - eldivider - 200
						})
					}
				}).catch(error => {
					this.loading = false
				})
			},
			showChildQR(item) {
				this.dialogType = "showQr"
				this.item = item
				console.log(item)
				this.dialogTitle = `${item.name}的收款码`
				this.dialogVisible= true;
				
			},
			//新增粉丝按钮
			newFans(){
				this.item={
					username:"",
					name:"",
					password:"",
					password1:'',
					telephone:"",
					skype:"",
				}
				this.feeList()
				this.dialogType = "new"
				this.dialogTitle = "新增粉丝"
				this.dialogVisible= true;
				
			},
			//编辑粉丝按钮
			editFans(item){
				this.item =JSON.parse(JSON.stringify(item)) 
				this.info()
				this.feeList(item.id)
				this.dialogType = "edit"
				this.dialogTitle = "新增粉丝"
				this.dialogVisible= true
			},
			close(){
				this.item = {}
				this.dialogTitle = ""
				this.dialogType=""
				this.dialogVisible= false;
			},
			sure(){
				if(this.dialogType == "new"){
					//新增粉丝
					this.addFans()
				}
				if(this.dialogType == "edit"){
					//编辑粉丝
					this.setFee()
				}
			},
			addFans(){
				if (isEmpty(this.item.name, "请输入姓名")) {
					return
				}
				if (isEmpty(this.item.username, "请输入登录账号")) {
					return
				}
				if (isEmpty(this.item.telephone, "请输入电话号码")) {
					return
				}
				if (!isMobile(this.item.telephone, "输入的电话号码不合法")) {
					return
				}
				if (isEmpty(this.item.password, "请输入登录密码")) {
					return
				}
				if(this.item.password1 != this.item.password){
					this.$message({
						type: 'error',
						message: "两次输入的密码不一致"
					})
					return
				}
				let error = ""
				for(let i = 0; i<this.feiArr.length;i++){
					let item = this.feiArr[i]
					// this.$forceUpdate()
					if(item.fee.toString()==""){
						// console.log(item.fee.toString())
						error = `${item.name}的费率不能为空`					
						break
					}
					if(Number(item.fee)> item.max_fee){
						error= `${item.name}的费率不能大于${item.max_fee}`
						break
					}
					if(Number(item.fee)< item.min_fee){
						error= `${item.name}的费率不能小于${item.min_fee}`	
						break
					}
				}
				if(error !=""){
					this.$message({
						type: 'error',
						message: error
					})
					return
				}
				let fee =  this.feiArr.map(item=>{
					return{
						fee:item.fee,
						pay_type:item.pay_type,
						"state": item.stateBoolean ? 1:0,
					}
				})
				let data = {
					carder_username: this.item.username,
					name: this.item.name,
					password: this.$md5(this.item.password),
					fee: JSON.stringify(fee),
					telephone: this.item.telephone,
					skype: this.item.skype
				}
				this.dialogloading = true
				this.$api.addFans(data).then(res=>{
					this.dialogloading = false
					if(res.status == 1){
						this.close()
						this.fans()						
					}else{
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error=>{
					this.dialogloading = false
				})
			},
			setFee(){
				if(this.item.id){
					let error=""
					for(let i = 0;i<this.feiArr.length;i++){
						let item= this.feiArr[i]
						if(item.fee.toString()==""){
							error = `${item.name}的费率不能为空`					
							break
						}
						if(Number(item.fee)> item.max_fee){
							error= `${item.name}的费率不能大于${item.max_fee}`
							break
						}
						if(Number(item.fee)< item.min_fee){
							error= `${item.name}的费率不能小于${item.min_fee}`	
							break
						}
					}
					if(error !=""){
						this.$message({
							type: 'error',
							message: error
						})
						return
					}
					
					let fee =  this.feiArr.map(item=>{
						return{
							fee:item.fee,
							pay_type:item.pay_type,
							"state": item.stateBoolean ? 1:0,
						}
					})
					let data = {
						carder_id: this.item.id,
						data: JSON.stringify(fee)
					}
					this.dialogloading = true
					this.$api.setFee(data).then(res=>{
						this.dialogloading = false
						if(res.status == 1){
							this.close()
							// this.fans()						
						}
					}).catch(error=>{
						this.dialogloading = false
					})
				}
				
			},
			
		},
		watch: {
			total(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
				})
			}
		},
	};
</script>

<style scoped="scoped" lang="scss">
.breadfs{
	cursor:pointer;
	&:hover{
		color:#409EFF;
	}
}
	.formBoxbd {
		width: 80%;
		margin: 0 auto;
	}
	.formBox{
		padding-right:10px;
		max-height : calc(100vh - 40px - 40px - 0.8333333333333vw - 0.5208333333333vw - 1.0416666666667vw - 1.3020833333333vw - 1.5625vw);
		overflow: auto;
		.label{
			width: 260px;
			// text-align:right;
		}
		.margin-top{
            font-size: 16px;
        }
	}
	.footerDialog{
		height:30px;
		padding-top: 10px;

	}
</style>
<style type="text/css">
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
