<template>
  <div>
    <div class="queryBox">
        <div class="flexX flexcenter mb20">
        <div class="mr10" style="width: auto;">最小金额：</div>
        <el-input
            style="width: 150px;"
            class="textCenter mr50"
            type="number"
            v-model="check_min_gold"
            placeholder="请输入最小金额"
            size="100px"
        />
        <div class="mr10" style="width: auto;">最大金额：</div>
        <el-input
            style="width: 150px;"
            class="textCenter mr10"
            type="number"
            v-model="check_max_gold"
            placeholder="请输入最大金额"
            size="100px"
        />
        <el-button size="mini" type="primary" :icon="loading?'el-icon-loading':'el-icon-search'" @click="getData" :disabled="loading">查询</el-button>
        </div>
    </div>
    <div class="flexX flexWrap">
        <el-card v-for="item in qdData" :key="item.id" class="box-card">
            <div  class="text item">
                <el-descriptions class="margin-top"  :column="1" size="medium">	
					<el-descriptions-item label="金额"> 
						<el-tag type="success"><span class="fs24">￥{{item.gold.toFixed(2)}}</span></el-tag>
						<!-- <span class="fs24" style="fontWeight:600;lineHeight:1.1">￥{{item.gold}}</span> -->
					</el-descriptions-item>
					<el-descriptions-item label="银行"> {{item.bank}}</el-descriptions-item>
					<el-descriptions-item label="卡号"> {{item.card}}</el-descriptions-item>
					<el-descriptions-item label="姓名"> {{item.name}}</el-descriptions-item>
					<el-descriptions-item label="释放次数">
                        <div class="flexX flexcenter">
                            <span class="mr10 cdanger">{{item.reject_count}}</span>
                            <el-popover
                            v-if="item.reject_count"
                            placement="right"
                            trigger="click">
                                <el-table :data="operatorLogData">
                                    <el-table-column width="150" property="add_time" label="时间"></el-table-column>
                                    <el-table-column width="300" property="bank_receipt" label="释放原因"></el-table-column>
                                </el-table>
                                <el-button type="primary" plain size="mini"  slot="reference" @click="getOrderPayNote(item.id)">查看</el-button>
                            </el-popover>
                        </div>
                    </el-descriptions-item>
					<el-descriptions-item label="提交时间"> {{item.add_time}}</el-descriptions-item>
				</el-descriptions>
                <div class="flexX flexEnd"> <el-button type="danger" size="mini"  @click="qdSure(item)">抢单</el-button></div>
               
            </div>
        </el-card>
    </div>
    <!-- <el-table style="width: 800px;" :data="qdData" size="mini" :max-height="height" stripe v-loading="loading" :show-overflow-tooltip="true">
      <el-table-column  property="add_time"  label="提交时间" width="150"></el-table-column>
      <el-table-column property="gold" label="金额"></el-table-column>
      <el-table-column property="bank" label="类型" width="200" ></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <div v-if="scope.row.id">
            <div class="pointer cprimary" @click="qdSure(scope.row)">抢单</div>
          </div>
        </template>
      </el-table-column>
    </el-table> -->
    <div class="flexX  mt20 pageBox">
        <el-pagination :hide-on-single-page="true" @size-change="handleSizeChange"
            @current-change="handleCurrentChange" :current-page="page" :page-sizes="pageSizesArr"
            :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </div>
	
  </div>
</template>
<script>
 
	import local from '@/util/local'
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isMobile,
		isIncludeHanzi
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
		export default {
		data() {
			return {
                check_min_gold: 0,
				check_max_gold: 50000,
				qdData: [],
				loading: false,
				height: 0,
                pageSizesArr: [20, 50, 100, 200],
				pageSize: 20,
				total: 0,
				page: 1,	
                
				operatorLogData: [],			
			};
		},
		created() {
            
			this.orderQdList()
		
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
			})
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					let val = Math.floor(((value * 1) * 100).toPrecision(12))
					return ((val / 100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			// 可抢单的订单列表.
			orderQdList() {
				let data = {
                    page: this.page,
					size: this.pageSize,
					min_gold: String(this.check_min_gold),
					max_gold: String(this.check_max_gold),
				}
				this.loading = true
				this.$api.orderOutSnatchList(data).then(res => {
					this.loading = false;
					if (res.status === 1) {
						this.qdData = res.data.data
                        // this.qdData.push(...res.data.data)
                        this.total = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading = false;
				})
			}, 
			qdSure(item){
				let data ={
					id:item.id,
				}
				this.$confirm('确认抢单吗?', '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.loading = true
					this.$api.orderOutSnatch(data).then(res=>{
						this.loading = false
						if(res.status == 1){
							this.orderQdList()
							this.$router.push({
                                path: '/orderPay'
                            })
							this.$message({
								type: 'success',
								message: res.msg
							})
						}else{
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
						
					}).catch(e=>{
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消抢单'
					});
				});
			},
			
			//搜索按钮
			getData() {
				this.page = 1;
				this.orderQdList();
			},

			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val
				this.page = 1
				this.orderQdList()
			},
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.page = val
				this.orderQdList()
			},
            showRelease(){

            },
            getOrderPayNote(id) {
				this.operatorLogData = []
				let data = {
					id:id
				}
				this.$api.getOrderPayNote(data).then(res => {
					if (res.status === 1) {
						this.operatorLogData = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
		},
		watch: {
			total(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
				})
			}
		},
	};
</script>
<style lang="scss" scoped>
	.box-card{
        margin: 25px;
        width:360px;
        .margin-top{
            font-size: 16px;
        }
    }
</style>