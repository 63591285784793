<template>
	<div>
		<div class="queryBox mb20 ">
			<!-- <div class="mb20">
				<span class="mr20">共{{payList.total_count}}个</span>
				<span class="mr20">启用： {{payList.enable_count}}个</span>
				<span class="mr20">停用： {{payList.total_count - payList.enable_count}}个</span>
				<span class="mr20">在线： {{payList.online_count}}个</span>
			</div> -->
			<div class="mb20 flexX">
				<!-- <el-button class="mr20" size="mini" type="primary" @click="newqr('new')">新增</el-button> -->
				<el-button class="mr20" size="mini" type="primary" @click="showAddItem()">新增</el-button>
				<BatchOperate class = "ml10" :multipleSelection='multipleSelection' :disLoading = "disLoading" :tableDataLength="tableDataLength" @updateList = "alipayList"/>
				
				<!-- <el-button class="mr20" size="mini" type="warning" @click="stateAll(0)" v-if="false"
					:disabled="disLoading || operating || tableData.length == 0 || multipleSelection.length == 0">批量停用
				</el-button>
				<el-button class="mr20" size="mini" type="success" @click="stateAll(1)" v-if="false"
					:disabled="disLoading || operating || tableData.length == 0 || multipleSelection.length == 0">批量启用
				</el-button>
				<el-button size="mini" type="danger" @click="deleteAll()" v-if="false"
					:disabled="disLoading || operating || tableData.length == 0 || multipleSelection.length == 0">批量删除
				</el-button> -->
			</div>
			<div class="flexX flexcenter">
				<span>状态：</span>
				<el-select size="mini" class="mr20" v-model="state" clearable placeholder="请选择状态">
					<el-option v-for="(item, index) in stateArr" :key="item.key" :label="item.val" :value="item.key">
					</el-option>
				</el-select>
				<!-- <span>金额：</span>
				<el-select size="mini" class="mr20" v-model="money" clearable placeholder="请选择金额">
					<el-option v-for="(item) in moneyArr" :key="item" :label="item" :value="item"></el-option>
				</el-select> -->
				<el-button size="mini" type="primary" :icon="disLoading ? 'el-icon-loading' : 'el-icon-search'" @click="getData"
					:disabled="disLoading">查询</el-button>
			</div>
		</div>
		<template>
			<el-table :max-height="height" :data="tableData" stripe style="width: 100%" v-loading="loading"
				@selection-change="handleSelectionChange" size="medium" :show-overflow-tooltip="true">
				<el-table-column type="selection"  width="55"></el-table-column>
				<el-table-column type="index" label="序号"  width="120px">
					<template slot-scope="scope">
						<div class="errorDiv" v-if="scope.row.id == index" v-for="(item, index) in errorArr">{{ item }}
						</div>
						<span>{{ scope.$index + 1 }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="mobile_name" label="机位" ></el-table-column>
				<el-table-column prop="account" label="账号" ></el-table-column>
				<!-- <el-table-column prop="true_name" label="真实姓名" ></el-table-column> -->
				<!-- <el-table-column prop="nickname" label="昵称" ></el-table-column> -->
				<!-- <el-table-column prop="telephone" label="手机号码" ></el-table-column> -->
				<!-- <el-table-column prop="ali_userid" label="UserId" ></el-table-column> -->
				<!-- <el-table-column prop="eve_used_money" label="日收上限" ></el-table-column> -->
				<el-table-column prop="eve_used_num" label="日收笔数" ></el-table-column>
				<!-- <el-table-column prop="callback_id" label="监控ID" ></el-table-column> -->
				
				<el-table-column label="启停状态" >
					<template slot-scope="scope">
						<span :class="scope.row.state == 0 ? 'csuccess' : 'cdanger'">{{ scope.row.state == 0 ? '启用' : '禁用' }}</span>
					</template>
				</el-table-column>
				<!-- <el-table-column label="在线状态" >
					<template slot-scope="scope">
						<span :class="scope.row.is_online == 1 ? 'csuccess' :scope.row.is_online == 2?'cwarning':'cdanger'">{{  scope.row.is_online == 1?'在线': scope.row.is_online == 2?'掉线':'离线'}}</span>
					</template>
				</el-table-column> -->
				
				<el-table-column label="可收/已收(笔数)" >
					<template slot-scope="scope">
						<span :class="scope.row.total_num == scope.row.used_num ? 'cdanger' :'csuccess'">{{scope.row.total_num}} / {{scope.row.used_num}}</span>
					</template>
				</el-table-column>
				
				<el-table-column label="状态" >
					<template slot-scope="scope">
						<span v-if="scope.row.pay_link_reason == '客户未充值'" class='cdanger'>{{scope.row.pay_link_reason}}</span>
						<span v-else-if="scope.row.pay_link_reason == '充值成功'" class='csuccess'>{{scope.row.pay_link_reason}}</span>
						<span v-else class='cwarning'>{{scope.row.pay_link_reason}}</span>
						
						
					</template>
				</el-table-column>
				<el-table-column prop="add_time" label="上码时间" ></el-table-column>
				<el-table-column prop="match_gold" label="匹配金额" ></el-table-column>
				<!-- <el-table-column prop="gold" label="收款金额" ></el-table-column> -->
				<el-table-column label="收款二维码" >
					<template slot-scope="scope" v-if="scope.row.qrcode_link">
						<el-image
							style="width: 30px; height: 50px"
							v-if="scope.row.qrcode_link != ''"
							:src="scope.row.qrcode_link"
							fit="fill"
							:preview-src-list="[scope.row.qrcode_link]">
						</el-image>
					</template>
				</el-table-column>
				<!-- <el-table-column label="好友二维码" >
					<template slot-scope="scope" v-if="scope.row.frcode_link">
						<el-image
							style="width: 30px; height: 50px"
							v-if="scope.row.frcode_link != ''"
							:src="scope.row.frcode_link"
							fit="fill"
							:preview-src-list="[scope.row.frcode_link]">
						</el-image>
					</template>
				</el-table-column> -->
				<el-table-column label="操作" >
					<template slot-scope="scope">
						<div >
							<!-- <el-link type="primary" @click="edit(scope.row)">编辑</el-link>
							</br> -->
							<!-- <el-button class="mt10" :disabled="operating" :type="scope.row.state == 0 ? 'warning' : 'success'" size="mini" @click="setState(scope.row)">{{ scope.row.state == 0 ? '停用' : '启用' }}</el-button>
							</br> -->
							<el-button class="mt10" :disabled="operating" type="danger" size="mini" @click="deleteQr(scope.row)">删除</el-button>
							
						</div>
					</template>
				</el-table-column>
			</el-table>
		</template>

		<div class="flexX  mt20 pageBox">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page" :page-sizes="pageSizesArr" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
		
		
		<AddTao v-if="showAddItemComponent" @close="handleClose"  :params="params" />
	</div>
	
</template>

<script>
import local from '@/util/local'
import {
	isEmpty,
	isPositiveNumber,
	isMoney,
	isMobile
} from '@/util/validate'
import {
	Message
} from 'element-ui'
import QRCode2 from 'qrcodejs2'
import QrCode from '../../../node_modules/qrcode-decoder/dist/index'
// import QrCode from '../node_modules/qrcode-decoder/dist/index';
import AddTao from '@/components/AddTaoHfZfb.vue';

import BatchOperate from '@/components/batchOperate'
export default {
	components: {
	    AddTao, // 注册组件
		BatchOperate,
	  },
	data() {
		return {
			tableData: [],
			tableDataLength:0,
			height: 0,
			loading: false,
			disLoading: false,
			pageSizesArr: [20, 50, 100, 200],
			pageSize: 20,
			total: 0,
			page: 1,
			state: '',
			stateArr: [{
				key: '',
				val: "全部"
			},
			{
				key: '0',
				val: "启用"
			},
			{
				key: '1',
				val: "停用"
			}
			],
			money: '',
			moneyArr: [],
			id: '',
			operating: false, //正在操作
			multipleSelection: [], //选中数组
			showPopup: false,
			errorArr: {},
			fileList: [],
			uploadData: {},
			upErrorArr: [],


			pay_type: '',
			item: {},
			storeInfo: {},
			dialogTitle: "",
			dialogType: '',
			dialogVisible: false,
			dialogloading: false,
			closeModal: false, //是否点击弹框 关闭，
			dataDetails: {
				true_name: "",
				account_branch: "",
				card: "",
				bank: "",
				telephone: "",
				state: 0,
				province: "",
				city: "",
				login_password: "",
				pay_password: "",
				account: '',
				bank_name: '',
				nickname: '',
				data: {},
				callback_id: '',
				year_total_money: '',
				eve_used_money: '',
				eve_used_num: '',
				qrcode_url: '',
				frcode_link:'',
				qrcode_link:'',
				gold: '',
				total_num: '',
				match_gold: '100',
				hd_order_id: '',
				mobile_id: '',
			},
			innerVisibleImg:false,
			aliState: true,
			fileurl: null,
			fileurlFriends:null,
			showImg:null,
			payList:{},
			qrImgTitle:'',
			radio: '1',
			matchMoney: [{
					key: '',
					val: "未选择"
				},
				{
					key: '50',
					val: "50"
				},
				{
					key: '100',
					val: "100"
				},
				{
					key: '200',
					val: "200"
				}
			],
			notUsedTime: 0, // 初始倒计时时间
			timer: null, // 倒计时定时器
			canGetAccount: true, // 是否允许重新获取账号
			// 机位
			mobileData: [],
			// 新增码组件
			showAddItemComponent: false, // 控制组件显示状态
			params:{},
		};
	},
	created() {
		this.payTypeAll(this.$route.meta.pay_type)
		
		if (this.$route.query.id) {
			this.id = this.$route.query.id;
		}
		if (this.$route.query.money) {
			this.money = this.$route.query.money;
		}
		if (this.$route.query.state) {
			this.state = this.$route.query.state;
		}
		this.uploadData = {
			username: local.get('bpcusername'),
			tid: local.get('bpctid'),
			store_id: this.id,
			login_type: 2,
		};
		// this.getStoreMoneyGroup()
		this.alipayList()
		this.$nextTick(() => {
			let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
			let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
			let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
			let documentHeight = document.documentElement.clientHeight
			this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
		})
	},
	filters: {
		tofixed: function (value) {
			if (value || value == 0) {
				// console.log('sssssssssss', (value * 1).toFixed(2) + '')
				// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				let val = Math.floor(((value * 1) * 100).toPrecision(12))
				return ((val / 100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
			}
		}
	},
	methods: {
		showAddItem() {
		    this.showAddItemComponent = true;
			this.params = {
				'type':'new',
				'dialogVisible': true,
				'pay_type': this.$route.meta.pay_type,
				}
		},
		  handleClose() {
			this.showAddItemComponent = false; // 关闭组件
			this.alipayList(); // 调用获取页面数据的方法
		  },
		payTypeAll(type) {
			let data = {}
			this.$api.payTypeAll(data).then(res=> {
				if(res.status == 1){
					res.data.map(item=>{
						if(item.id == type){
							this.payList =item 
						}
					})
				}
				else{
					this.$message({
						type: 'error',
						message: res.msg
					})
				}					
			})
		},
		getStoreMoneyGroup() {
			this.$api.getStoreMoneyGroup({
				store_id: this.id
			}).then(res => {
				if (res.status == 1) {
					res.data.splice(0, 0, '全部')
					this.moneyArr = JSON.parse(JSON.stringify(res.data))

				}
			})
		},
		getData() {
			this.alipayList()
		},
		alipayList() {
			let data = {
				state: this.state,
				pay_type: this.$route.meta.pay_type,
				page: this.page,
				size: this.pageSize,
			}
			this.disLoading = true
			this.$api.alipayList(data).then(res => {
				this.loading = false;
				this.disLoading = false;
				if (res.status === 1) {
					this.tableData = res.data.data;
					this.tableDataLength = this.tableData.length
					this.total = res.data.total
				} else {
					this.$message({
						type: 'error',
						message: res.msg
					})
				}
			}).catch(error => {
				console.log(error)
				this.loading = false
				this.disLoading = false
			})
		},
		handleSizeChange(val) {
			this.pageSize = val
			this.page = 1
			this.alipayList()
		},
		handleCurrentChange(val) {
			this.page = val
			this.alipayList()
		},
		handleSelectionChange(val) {
			// console.log(val)
			this.multipleSelection = val;
		},
		// deleteAll() {
		// 	let selectArr = this.multipleSelection.filter(item => {
		// 		if (item.id) {
		// 			return item
		// 		}
		// 	})
		// 	let dataId = selectArr.map(item => {
		// 		return item.id
		// 	})
		// 	let data = {
		// 		qr_id: dataId.join(',')
		// 	}
		// 	console.log(data)
		// 	this.deleteStoreQr(data)
		// },
		// stateAll(state) {
		// 	let selectArr = this.multipleSelection.filter(item => {
		// 		if (item.id) {
		// 			return item
		// 		}
		// 	})
		// 	let dataId = selectArr.map(item => {
		// 		return item.id
		// 	})
		// 	let data = {
		// 		state: state,
		// 		qr_id: dataId.join(',')
		// 	}
		// 	console.log(data)
		// 	this.setStoreQrState(data)
		// },
		deleteQr(item) {
			let data = {
				id: item.id
			}
			this.deleteStoreQr(data)
		},
		setState(item) {
			this.dataDetails = JSON.parse(JSON.stringify(item))
			this.fileurl = this.dataDetails.qrcode_url
			let data = {
				id: this.dataDetails.id,
					true_name: this.dataDetails.true_name,
					account: this.dataDetails.account,
					nickname: this.dataDetails.nickname,
					telephone: this.dataDetails.telephone,
					qrcode_url: this.dataDetails.qrcode_url,
					qrcode_link: this.dataDetails.qrcode_link,
					frcode_url: this.dataDetails.frcode_url,
					ali_userid: this.dataDetails.ali_userid,
					state: this.dataDetails.state == 0?1:0,
					pay_type: this.$route.meta.pay_type, // 支付宝
					callback_id: this.dataDetails.callback_id,
					eve_used_num: this.dataDetails.eve_used_num,
					eve_used_money: this.dataDetails.eve_used_money,
					gold: this.dataDetails.gold,
					total_num: this.dataDetails.total_num,
					match_gold: this.dataDetails.match_gold
			}
			this.$api.editAlipay(data).then(res => {
					if (res.status == 1) {
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.close()
						this.getData()
					} else {
						this.close()
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
			}).catch(error=>{
				console.log(error)
				this.close()
			})
		},
		// setStoreQrState(data) {
		// 	this.$confirm(`确定改变选择收款码的启停状态吗?`, '提示', {
		// 		confirmButtonText: '确定',
		// 		cancelButtonText: '取消',
		// 		type: 'warning'
		// 	}).then(() => {
		// 		this.operating = true
		// 		console.log('==============')
		// 		this.$api.setStoreQrState(data).then(res => {
		// 			console.log("res")
		// 			this.operating = false
		// 			if (res.status == 1) {
		// 				this.alipayList()
		// 			} else {
		// 				this.errorArr = res.data
		// 			}
		// 		}).catch(error => {
		// 			this.operating = false
		// 		})
		// 	}).catch(() => {
		// 		this.operating = false
		// 		this.$message({
		// 			type: 'info',
		// 			message: '已取消操作'
		// 		});
		// 	});
		// },
		deleteStoreQr(data) {
			this.$confirm(`确定删除支付宝吗?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.operating = true
				this.$api.deleteAlipay(data).then(res => {
					this.operating = false
					if (res.status == 1) {
						this.alipayList()
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
						this.errorArr = res.data
					}
				}).catch(error => {
					this.operating = false
				})
			}).catch(() => {
				this.operating = false
				this.$message({
					type: 'info',
					message: '已取消操作'
				});
			});
		},
		showQr(url) {
			this.showPopup = true
			document.getElementById('qr').innerHTML = '';
			var qrcode = new QRCode2(this.$refs.qrCodeUrl, {
				text: url, // 需要转换为二维码的内容
				width: 400,
				height: 400,
				colorDark: '#000000',
				colorLight: '#ffffff',
				correctLevel: QRCode2.CorrectLevel.H
			})
		},
		closePopup() {
			this.showPopup = false
		},
		newqr() {
			let queryData = {}
			queryData['id'] = this.id
			// this.$router.push({
			// 	path: '/passageAlisub2',
			// 	query: queryData,
			// })
			this.dialogType = 'new'
			this.dialogTitle = "新增"
			this.dialogVisible = true
			this.notUsedTime = 0
			this.canGetAccount = true
		},
		edit(item) {
			this.dialogType = 'show'
			this.dialogTitle = '编辑'
			this.dataDetails = JSON.parse(JSON.stringify(item))
			this.fileurl = this.dataDetails.qrcode_link
			this.fileurlFriends = this.dataDetails.frcode_link
			this.aliState = this.dataDetails.state == 0?true:false
			this.dialogVisible = true
			console.log(this.dataDetails)

		},


	},
	watch: {

		total(val, old) {
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				let threeDirectoryBox = document.getElementsByClassName('threeDirectoryBox')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - threeDirectoryBox - 40
			})
		}
	},

};
</script>

<style scoped="scoped" lang="scss">
@import '@/style/variables.scss';

.dataKey {
	display: inline-block;
	flex: 0 0 100px;
	text-align: left;
}

.qrIcon {
	display: inline-block;
	background: url(../../assets/image/qr.png);
	width: 30px;
	height: 30px;
	background-size: 100% 100%;
}

.errorDiv {
	white-space: nowrap;
	z-index: 2;
	position: absolute;
	top: 0;
	font-size: 10px;
	color: $danger
}

#uploadImg {
	display: none;
}

.upload-img {
	width: 100px;
	height: 100px;
}

.popup {
	position: fixed;
	left: 0;
	top: 0;
	display: none;
	background: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100%;
	z-index: 9999;

	&.show {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.qrcode {
		display: inline-block;

		img {
			width: 400px;
			height: 400;
			background-color: #fff; //设置白色背景色
			padding: 20px; // 利用padding的特性，挤出白边
			box-sizing: border-box;
		}
	}
}
.image-dialog{
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
}
</style>
